import React, { Component } from "react";
import "./App.css";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Routes from "./routes";
import { SnackbarProvider } from "notistack";
import { Button } from "@material-ui/core";
import { REQUEST_ORGANIZATION } from "./actions/actions";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3182CE", // "#61b47b", // #fafafa
    },
    primaryButton: {
      main: "#007bff",
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class App extends Component {
  componentDidMount() {
    this.props.getOrganization();
  }

  render() {
    return (
      <SnackbarProvider
        maxSnack={3}
        action={<Button>{"X"}</Button>}
        preventDuplicate
      >
        <MuiThemeProvider theme={theme}>
          {/* <head> */}
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500"
          />
          {/* </head> */}
          <Routes />
        </MuiThemeProvider>
      </SnackbarProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthorized: state.auth.isAuthorized,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrganization: () =>
      dispatch({
        type: REQUEST_ORGANIZATION,
        organizationId: process.env.REACT_APP_ORGANIZATION_ID,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

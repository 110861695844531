import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import {
  REQUEST_EVENTS,
  REQUEST_ORGANIZATION,
  FETCH_ACCESS_TOKEN_SUCCESS,
} from "../actions/actions";
import AppointmentOptions from "../components/AppointmentOptions";
import { getAccessToken } from "../utils/asyncSettingsServerRequests";
import { withSnackbar } from "notistack";
import NavBar from "../components/NavBar";

const styles = () => ({
  buttonProgress: {
    position: "relative",
    left: "50%",
  },
});

class AppointmentOptionsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      fetched: false,
      accessTokenSet: false,
      error: null,
    };

    this.handlePageLoad = this.handlePageLoad.bind(this);
  }

  componentDidMount() {
    this.handlePageLoad();
  }

  handlePageLoad = async () => {
    this.setState({
      fetching: true,
      fetched: false,
    });
    let jwtResponse = await getAccessToken();
    if (jwtResponse && jwtResponse.code < 400) {
      this.setState({
        fetching: false,
        fetched: true,
      });
      this.props.setAccessToken(jwtResponse.access_token);
      window.analytics.page("Patient Self Schedule");
      this.props.getEvents();
    } else {
      window.analytics.track(
        "Error getting patient self schedule access token"
      );
      let message = jwtResponse ? jwtResponse.message : "Error";
      console.error(message);
      this.setState({
        fetching: false,
        fetched: false,
        error: message,
      });
      // this.props.enqueueSnackbar("Error Loading Page", {
      //   variant: "error",
      // });
    }
  };

  render() {
    if (this.props.disableSelfScheduling) {
      return (
        <NavBar>
          <h1>
            Self-scheduling is not supported through this page. Please contact
            us directly to schedule.
          </h1>
        </NavBar>
      );
    } else {
      return (
        <AppointmentOptions
          fetching={this.state.fetching || this.props.settingsFetching}
          error={this.state.error || this.props.settingsError}
          fetched={this.state.fetched && this.props.settingsFetched}
        />
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    settingsFetched: state.settings.fetched,
    settingsFetching: state.settings.fetching,
    settingsError: state.settings.error,
    disableSelfScheduling:
      state.settings.organization &&
      state.settings.organization.selfScheduling &&
      state.settings.organization.selfScheduling.disableSelfScheduling,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEvents: user => dispatch({ type: REQUEST_EVENTS, user: user }),
    getOrganization: () =>
      dispatch({
        type: REQUEST_ORGANIZATION,
        organizationId: process.env.REACT_APP_ORGANIZATION_ID,
      }),
    setAccessToken: access_token =>
      dispatch({
        type: FETCH_ACCESS_TOKEN_SUCCESS,
        access_token: access_token,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar(AppointmentOptionsContainer)));

// action types
import { ADD_PENDING_QUESTIONNAIRE_RESPONSE } from "../actions/actions";

const initialState = {
  pendingQuestionnaireResponses: [],
};

const questionnaireReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PENDING_QUESTIONNAIRE_RESPONSE: {
      let questionnaireResponses = [...state.pendingQuestionnaireResponses];
      questionnaireResponses.push(action.pendingQuestionnaireResponse);
      state = {
        ...state,
        pendingQuestionnaireResponses: questionnaireResponses,
      };
      break;
    }
    default: {
      break;
    }
  }
  return state;
};

export default questionnaireReducer;

import moment from "moment-timezone";

export const shiftPickerDateToTimezoneDate = (pickerDate, timezone) => {
  let pickerOffset = moment(pickerDate).utcOffset();
  let utcDate = new Date();
  utcDate.setTime(moment(pickerDate).valueOf() + pickerOffset * 60000);

  let tzOffset = moment.tz(pickerDate, timezone).utcOffset();
  let tzDate = new Date();
  tzDate.setTime(utcDate.getTime() - tzOffset * 60000);
  return tzDate;
};

export const shiftTimezoneDateToPickerDate = (tzDate, timezone) => {
  let tzUtcOffset = moment.tz(tzDate, timezone).utcOffset();
  let utcDate = new Date();
  utcDate.setTime(moment(tzDate).valueOf() + tzUtcOffset * 60000);

  let pickerDate = new Date();
  let pickerOffset = pickerDate.getTimezoneOffset();
  pickerDate.setTime(utcDate.getTime() + pickerOffset * 60000);

  return pickerDate;
};

export const shiftDateFromTimezoneA2B = (date, tz_a, tz_b) => {
  let tz_a_offset = moment.tz(date, tz_a).utcOffset();
  let tz_b_offset = moment.tz(date, tz_b).utcOffset();
  let tz_b_date = new Date();
  tz_b_date.setTime(
    moment(date).valueOf() - (tz_a_offset - tz_b_offset) * 60000
  );

  return tz_b_date;
};

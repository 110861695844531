import React from "react";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import { Grid, Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import {
  date,
  checkbox,
  text,
  phone,
  radio,
  dropdown,
} from "./renderers/renderers";
import renderSubmitButton from "./renderers/SubmitFormButton";
import { phoneValidator } from "./validators";
import * as Yup from "yup";
import { withSnackbar } from "notistack";
import {
  SET_PATIENT,
  ADD_PENDING_QUESTIONNAIRE_RESPONSE,
} from "../actions/actions";
import {
  createQuestionnaireResponseResource,
  isObjEmpty,
} from "../utils/resources";
import { submitQuestionnaireResponses } from "../utils/asyncServerRequests";
import { errorClass } from "./consts";
import QuestionnaireItem from "../components/QuestionnaireItem";

const styles = theme => ({
  icon: {
    margin: theme.spacing.unit * 2,
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    ":hover": {
      color: red[800],
    },
  },
  paper: {
    minWidth: "fit-content",
    width: "80%",
  },
  select: {
    minWidth: "fit-content",
  },
  container: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
  },
  error: {
    color: "red",
  },
  avatar: {
    width: "4em",
    height: "4em",
  },
  tooltip: {
    fontSize: "2em",
  },
});

Yup.addMethod(Yup.string, "phone", function() {
  return this.test("phoneNumber", "Invalid phone number", function(value) {
    if (value) {
      value = value.replace(/\D/g, "").toString();
      if (
        value.length !== 10 ||
        value.charAt(0) === "0" ||
        value.charAt(0) === "1"
      ) {
        return false;
      }
      return phoneValidator(value);
    } else {
      return true;
    }
  });
});

const QuestionnaireSchema = Yup.object().shape({
  //   firstName: Yup.string().required("Required"),
  //   lastName: Yup.string().required("Required"),
  //   email: Yup.string().email("Invalid email"),
  //   // .required("Required"),
  //   phoneNumber: Yup.string()
  //     .nullable()
  //     .phone(),
});

class QuestionnaireForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValues: this.getInitialValues(props.questionnaire.item),
      helpers: {},
      customErrorMessage: "",
    };

    this.addHelpIds = this.addHelpIds.bind(this);
  }

  componentDidMount() {
    this.getHelperValues(this.props.questionnaire.item);
    window.addEventListener("beforeunload", this.confirmLeave);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.confirmLeave);
  }

  confirmLeave = e => {
    const result = window.confirm(
      "Any unsaved changes will be lost. Are you sure that you want to leave?"
    );
    if (!result) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.questionnaire && this.props.questionnaire) {
      if (prevProps.questionnaire.id !== this.props.questionnaire.id) {
        this.setState({
          initialValues: this.getInitialValues(this.props.questionnaire.item),
        });
      }
    }
  }

  onKeyDown = keyEvent => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      if (keyEvent.target.tagName.toLowerCase() === "textarea") {
        return;
      }
      keyEvent.preventDefault();
    }
  };

  getHelperValues = item => {
    if (Array.isArray(item) && item.length > 0) {
      item.forEach((subItem, index) => {
        if (
          Array.isArray(subItem.item) &&
          subItem.item.length === 1 &&
          subItem.item[0].type === "display" &&
          Array.isArray(subItem.item[0].extension) &&
          subItem.item[0].extension.length === 1 &&
          subItem.item[0].extension[0].url ===
            "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl" &&
          subItem.item[0].extension[0].valueCodeableConcept &&
          Array.isArray(
            subItem.item[0].extension[0].valueCodeableConcept.coding
          ) &&
          subItem.item[0].extension[0].valueCodeableConcept.coding.length ===
            1 &&
          subItem.item[0].extension[0].valueCodeableConcept.coding[0].code ===
            "help"
        ) {
          this.addHelpIds(subItem.item[0]);
        }
      });
    }
  };

  getInitialValues = item => {
    let linkIds = {};
    if (Array.isArray(item) && item.length > 0) {
      item.forEach((item, index) => {
        if (item.type !== "display") {
          if (item.repeats) {
            linkIds[item.linkId] = [this.getInitialValues(item.item)];
          } else if (Array.isArray(item.item) && item.item.length > 0) {
            if (item.item.length === 1 && item.item[0].type === "display") {
              linkIds[item.linkId] = "";
            } else {
              linkIds = Object.assign(
                linkIds,
                this.getInitialValues(item.item)
              );
            }
          } else {
            linkIds[item.linkId] = "";
          }
        }
      });
    }
    // console.debug("initial values", linkIds);
    return linkIds;
  };

  addHelpIds = item => {
    this.setState({
      helpers: {
        [item.linkId]: item.text,
      },
    });
  };

  getComponentForType = type => {
    switch (type) {
      case "text": {
        return text;
      }
      case "date": {
        return date;
      }
      case "checkbox": {
        return checkbox;
      }
      case "phone": {
        return phone;
      }
      case "radio": {
        return radio;
      }
      case "dropdown": {
        return dropdown;
      }
      default: {
        break;
      }
    }
  };

  handleSubmit = async (values, actions) => {
    let customErrorMsg = "";
    let questionnaireData = { ...values };

    // Custom validations
    const keys = Object.keys(questionnaireData);
    keys.forEach(key => {
      if (customErrorMsg.length < 1) {
        const formProp = questionnaireData[key];
        if (Array.isArray(formProp)) {
          for (const childFormProp of formProp) {
            if (
              childFormProp["allergyReaction"] &&
              (isObjEmpty(childFormProp["allergy"]) ||
                (typeof childFormProp["allergy"] === "string" &&
                  childFormProp["allergy"].trim().length < 1))
            ) {
              customErrorMsg =
                "* You must enter an allergy if you enter an allergy reaction";
              break;
            }
          }
        }
      }
    });
    this.setState({
      customErrorMessage: customErrorMsg,
    });
    if (customErrorMsg.length > 0) {
      actions.setSubmitting(false);
      return false;
    }

    let resource = createQuestionnaireResponseResource(
      this.props.patient,
      this.props.questionnaire,
      questionnaireData,
      null // this.props.savedQuestionnaireAnswers
    );
    if (this.props.formBundle.allowPartialSubmission) {
      const response = await submitQuestionnaireResponses(
        [resource],
        this.props.formBundle,
        this.props.patient,
        this.props.newPatient
      );
      if (!(response && response.statusCode < 400)) {
        const message =
          response && response.error ? response.error : "Error submitting form";
        this.props.enqueueSnackbar(message, {
          variant: "error",
        });
      }
    } else {
      this.props.addPendingQuestionnaireResponses(resource);
    }
    await this.props.handleQuestionnaireSubmit();
    actions.setSubmitting(false);
  };

  render() {
    const { classes } = this.props;
    return (
      <Formik
        key={this.props.questionnaire.id}
        initialValues={this.state.initialValues}
        validationSchema={QuestionnaireSchema}
        onSubmit={this.handleSubmit}
        className={classes.form}
        id="customForm"
      >
        {formProps => (
          <Form
            onKeyDown={this.onKeyDown}
            className="h-auto mx-auto w-full max-w-5xl bg-white shadow-md rounded px-2 py-2 pt-2 md:px-8 md:py-8 md:pt-8"
          >
            <div className="mx-auto w-full max-w-xl">
              <Grid container className={classes.container} spacing={16}>
                <QuestionnaireItem
                  item={this.props.questionnaire.item}
                  formProps={formProps}
                  helpers={this.state.helpers}
                />
              </Grid>
              <Hidden mdUp>
                <Field
                  name="submit"
                  component={renderSubmitButton}
                  buttonWidth="100%"
                >
                  Next
                </Field>
                {formProps.submitCount > 0 &&
                  formProps.errors &&
                  Object.keys(formProps.errors).length > 0 && (
                    <span className={errorClass}>
                      Please fix all errors & answer all required questions as
                      indicated by the *
                    </span>
                  )}
                {this.state.customErrorMessage.length > 0 && (
                  <>
                    <span className={errorClass} style={{ display: "block" }}>
                      {this.state.customErrorMessage}
                    </span>
                  </>
                )}
              </Hidden>
              <Hidden smDown>
                <Field name="submit" component={renderSubmitButton}>
                  Next
                </Field>
                {formProps.submitCount > 0 &&
                  formProps.errors &&
                  Object.keys(formProps.errors).length > 0 && (
                    <span className={errorClass}>
                      Please fix all errors & answer all required questions as
                      indicated by the *
                    </span>
                  )}
                {this.state.customErrorMessage.length > 0 && (
                  <>
                    <span className={errorClass} style={{ display: "block" }}>
                      {this.state.customErrorMessage}
                    </span>
                  </>
                )}
              </Hidden>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  return {
    organization: state.settings.organization,
    patient: state.patients.selectedPatient,
    newPatient: state.patients.newPatient,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedPatient: (custom, newPatient) =>
      dispatch({ type: SET_PATIENT, custom: custom, newPatient: newPatient }),
    addPendingQuestionnaireResponses: questionnaireResponse =>
      dispatch({
        type: ADD_PENDING_QUESTIONNAIRE_RESPONSE,
        pendingQuestionnaireResponse: questionnaireResponse,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar(QuestionnaireForm)));

// action types
import {
  REQUEST_SCHEDULE,
  FETCH_SCHEDULE_SUCCEEDED,
  FETCH_SCHEDULE_FAILED,
  REQUEST_PRACTITIONERS,
  FETCH_PRACTITIONERS_SUCCEEDED,
  FETCH_PRACTITIONERS_FAILED,
  REQUEST_UPDATE_EVENT,
  UPDATE_EVENT_SUCCESS,
  SELECT_PRACTITIONER,
  REQUEST_AVAILABLE_SLOTS,
  FETCH_AVAILABLE_SLOTS_SUCCESS,
  FETCH_AVAILABLE_SLOTS_FAILED,
  SET_SCHEDULE_FETCHING,
  SET_APPOINTMENT_SUCCESS,
  // REQUEST_CREATE_SLOT_APPOINTMENT,
  // CREATE_SLOT_APPOINTMENT_FAILED,
  // CREATE_SLOT_APPOINTMENT_SUCCEEDED,
} from "../actions/actions";

const initialState = {
  fetching: false,
  fetched: false,
  createEventSuccess: null,
  scheduleId: null,
  selectedPractitioner: null,
  error: null,
  slots: [],
  practitioners: [],
  appointments: [],
  bookAppointmentSuccess: false,
  bookedAppointment: null,
};

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SCHEDULE: {
      state = { ...state, fetching: true, fetched: false, error: null };
      break;
    }
    case FETCH_SCHEDULE_SUCCEEDED: {
      state = {
        ...state,
        fetching: false,
        fetched: true,
        scheduleId: action.scheduleId,
        slots: action.slots,
        appointments: action.appointments,
      };
      break;
    }
    case FETCH_SCHEDULE_FAILED: {
      state = {
        ...state,
        fetching: false,
        fetched: true,
        error: action.error,
        scheduleId: null,
      };
      break;
    }
    case REQUEST_PRACTITIONERS: {
      state = { ...state, fetching: true, fetched: false, error: null };
      break;
    }
    case FETCH_PRACTITIONERS_SUCCEEDED: {
      state = {
        ...state,
        fetching: false,
        fetched: true,
        practitioners: action.practitioners,
      };
      break;
    }
    case FETCH_PRACTITIONERS_FAILED: {
      state = {
        ...state,
        fetching: false,
        fetched: true,
        error: action.error,
        practitioners: [],
      };
      break;
    }
    case REQUEST_UPDATE_EVENT: {
      state = {
        ...state,
        updateEventSuccess: null,
      };
      break;
    }
    case UPDATE_EVENT_SUCCESS: {
      state = {
        ...state,
        updateEventSuccess: true,
      };
      break;
    }
    case SELECT_PRACTITIONER: {
      state = {
        ...state,
        selectedPractitioner: action.payload,
      };
      break;
    }
    case REQUEST_AVAILABLE_SLOTS: {
      state = { ...state, fetching: true, fetched: false, error: null };
      break;
    }
    case FETCH_AVAILABLE_SLOTS_SUCCESS: {
      state = { ...state, fetching: false, fetched: true, error: null };
      break;
    }
    case FETCH_AVAILABLE_SLOTS_FAILED: {
      state = { ...state, fetching: false, fetched: true, error: true };
      break;
    }
    case SET_SCHEDULE_FETCHING: {
      state = { ...state, fetching: true, fetched: false, error: null };
      break;
    }
    case SET_APPOINTMENT_SUCCESS: {
      state = {
        ...state,
        bookedAppointmentSuccess: true,
        bookedAppointment: {
          title: action.title,
          description: action.description,
          startTime: action.startTime,
          endTime: action.endTime,
          location: action.location,
        },
      };
      break;
    }
    default: {
      break;
    }
  }
  return state;
};

export default scheduleReducer;

import React from "react";
import PropTypes from "prop-types";
import ConfirmationDialog from "../components/ConfirmationDialog";
import Button from "@material-ui/core/Button";

class DeleteButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = { confirmationPopup: false };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open = () => {
    this.setState({ confirmationPopup: true });
  };

  close = () => {
    this.setState({ confirmationPopup: false });
  };

  render() {
    return (
      <React.Fragment>
        <Button
          variant="contained"
          color="secondary"
          type="button"
          disabled={this.props.disabled}
          onClick={
            this.props.noConfirmation
              ? () => this.props.onDelete()
              : () => this.open()
          }
        >
          {this.props.children}
        </Button>
        {!this.props.noConfirmation && (
          <ConfirmationDialog
            onCancel={() => this.close()}
            onConfirm={() => {
              this.close();
              this.props.onDelete();
            }}
            title={this.props.title}
            open={this.state.confirmationPopup}
            deleteForm={this.props.deleteForm}
          />
        )}
      </React.Fragment>
    );
  }
}

DeleteButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
};

export default DeleteButton;

import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { Grid, Hidden, DialogContent, FormHelperText } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import {
  date,
  singleCheckbox,
  string,
  phone,
  email,
  radio,
} from "./renderers/renderers";
import renderSubmitButton from "./renderers/SubmitFormButton";
import { phoneValidator } from "./validators";
import * as Yup from "yup";
import { withSnackbar } from "notistack";
import { SET_PATIENT } from "../actions/actions";
import {
  createPatientResource,
  getPatientHintMembership,
} from "../utils/resources";
import { getPatient } from "../utils/asyncServerRequests";
import ReCAPTCHA from "react-google-recaptcha";
import { errorClass } from "./consts";

const styles = theme => ({
  icon: {
    margin: theme.spacing.unit * 2,
  },
  iconHover: {
    margin: theme.spacing.unit * 2,
    ":hover": {
      color: red[800],
    },
  },
  paper: {
    minWidth: "fit-content",
    width: "80%",
  },
  select: {
    minWidth: "fit-content",
  },
  container: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
  },
  error: {
    color: "red",
  },
  avatar: {
    width: "4em",
    height: "4em",
  },
  tooltip: {
    fontSize: "2em",
  },
});

Yup.addMethod(Yup.string, "phone", function() {
  return this.test("phoneNumber", "Invalid phone number", function(value) {
    if (value) {
      value = value.replace(/\D/g, "").toString();
      if (
        value.length !== 10 ||
        value.charAt(0) === "0" ||
        value.charAt(0) === "1"
      ) {
        return false;
      }
      return phoneValidator(value);
    } else {
      return true;
    }
  });
});

const PatientSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  dob: Yup.string()
    .required("Required")
    .nullable(),
  email: Yup.string().email("Invalid email"),
  // .required("Required"),
  phoneNumber: Yup.string()
    .nullable()
    .phone(),
});

class PatientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      captchaSuccess:
        process.env.REACT_APP_ENVIRONMENT === "development" ? true : false,
      captchaError: "",
    };

    this.handleCaptchaSuccess = this.handleCaptchaSuccess.bind(this);
    this.handleCaptchaExpired = this.handleCaptchaExpired.bind(this);
    this.handleCaptchaError = this.handleCaptchaError.bind(this);
  }

  multiplePatientsFound = false;
  multipleDobFound = false;
  noPatientFound = false;
  error = null;
  isFormPage = window.location.pathname.toLowerCase().includes("forms")
    ? true
    : false;
  noNewPatientsAllowed = false;
  noUnconfirmedMembersAllowed = false;

  handleCaptchaSuccess = () => {
    this.setState({
      captchaSuccess: true,
    });
  };

  handleCaptchaExpired = expired => {
    this.setState({
      captchaSuccess: false,
      captchaError: "expired",
    });
    this.props.enqueueSnackbar("Captcha expired. Please try again", {
      variant: "error",
    });
  };

  handleCaptchaError = error => {
    this.setState({
      captchaSuccess: false,
      captchaError: typeof error === string ? error : "expired",
    });
    this.props.enqueueSnackbar("Captcha failed. Please try again", {
      variant: "error",
    });
  };

  handleFoundPatient = (patientResource, newPatient) => {
    this.props.setSelectedPatient(patientResource, newPatient);
  };

  handleSubmit = async (values, actions) => {
    let patientData = { ...values };
    if (patientData.phoneNumber) {
      patientData.phoneNumber = patientData.phoneNumber
        .replace(/\D/g, "")
        .toString();
      patientData.phoneType = "mobile";
    }
    if (patientData.gender) {
      patientData.gender = patientData.gender.value;
    }
    const patientResponse = await getPatient(patientData);
    if (patientResponse && patientResponse.code < 400) {
      if (
        patientResponse.numPatients > 0 &&
        patientResponse.numPatients === patientResponse.inactivePatients.length
      ) {
        this.error = "Your account is currently inactive.";
        actions.setSubmitting(false);
        return;
      } else if (patientResponse.activePatients.length > 1) {
        if (!this.multiplePatientsFound) {
          this.multiplePatientsFound = true;
          if (patientData.dob) {
            this.multipleDobFound = true;
          }
        } else {
          this.error = "Unable to match patient records.";
        }
        actions.setSubmitting(false);
        return;
      } else if (patientResponse.numPatients === 0 && !patientData.newPatient) {
        this.noPatientFound = true;
        actions.setSubmitting(false);
        return;
      } else if (patientResponse.numPatients === 0 && patientData.newPatient) {
        if (
          (!this.isFormPage &&
            this.props.organization.allowNewPatientAppointments) ||
          (this.isFormPage &&
            this.props.formBundle &&
            this.props.formBundle.allowNewPatients)
        ) {
          const patientResource = createPatientResource(
            patientData,
            process.env.REACT_APP_ORGANIZATION_ID
          );
          this.props.setSelectedPatient(
            patientResource,
            patientData.newPatient
          );
        } else {
          this.noPatientFound = true;
          this.noNewPatientsAllowed = true;
        }
      } else {
        if (
          this.props.organization.hintIntegration &&
          this.props.organization.hintIntegration.id &&
          !patientData.newPatient &&
          this.props.organization.selfScheduling &&
          !this.props.organization.selfScheduling.allowPendingMembership
        ) {
          let membership = getPatientHintMembership(
            patientResponse.activePatients[0]
          );
          if (membership === "unconfirmed" || membership === "pending") {
            this.noPatientFound = false;
            this.noUnconfirmedMembersAllowed = true;
          } else {
            this.handleFoundPatient(
              patientResponse.activePatients[0],
              patientData.newPatient
            );
          }
        } else {
          this.handleFoundPatient(
            patientResponse.activePatients[0],
            patientData.newPatient
          );
        }
      }
    } else {
      let message =
        patientResponse && typeof patientResponse.message === "string"
          ? patientResponse.message
          : "Error";
      this.props.enqueueSnackbar(message, {
        variant: "error",
      });
    }
    actions.setSubmitting(false);
    // handleDialogClose();
  };

  render() {
    const { classes } = this.props;
    return (
      <DialogContent className={classes.dialogPaper}>
        <Formik
          // enableReinitialize={true}
          initialValues={{
            prefix: "",
            firstName: "",
            lastName: "",
            dob: null,
            phoneNumber: "",
            email: "",
            newPatient: false,
            status: true,
            gender: "",
          }}
          validationSchema={PatientSchema}
          onSubmit={this.handleSubmit}
          className={classes.form}
          id="patientForm"
        >
          {formProps => (
            <Form className="min-h-full h-auto mx-auto w-full max-w-5xl bg-white shadow-md rounded px-8 py-8 pt-8">
              <div className="mx-auto w-full max-w-xl">
                <h2 className="text-lg font-bold">Patient Information</h2>
                <Grid container className={classes.container} spacing={16}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      classes={classes.paper}
                      fullWidth
                      name="firstName"
                      component={string}
                      label="Legal First Name"
                      required
                      variant="outlined"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="span"
                      className={errorClass}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      classes={classes.paper}
                      fullWidth
                      name="lastName"
                      component={string}
                      label="Legal Last Name"
                      required
                      variant="outlined"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="span"
                      className={errorClass}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="dob"
                      label="Date of Birth"
                      required
                      variant="outlined"
                      fullWidth
                      component={date}
                      enablePast={true}
                      helperText="MM/DD/YYYY"
                      disableOpenOnEnter
                      disableFuture
                      // timezone={organization.timezone}
                    />
                    <ErrorMessage
                      name="dob"
                      component="span"
                      className={errorClass}
                    />
                  </Grid>
                  {this.noPatientFound && formProps.values.newPatient && (
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="gender"
                        label="Birth Sex"
                        component={radio}
                        defaultValue={formProps.values.gender}
                        required
                        options={[
                          { value: "male", label: "Male" },
                          { value: "female", label: "Female" },
                          { value: "other", label: "Other" },
                        ]}
                        type="radio"
                      />
                      <ErrorMessage
                        name="gender"
                        component="span"
                        className={errorClass}
                      />
                    </Grid>
                  )}
                  {/* {(this.multiplePatientsFound ||
                    (this.noPatientFound && formProps.values.newPatient)) && (
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="dob"
                        label="Date of Birth"
                        required
                        variant="outlined"
                        fullWidth
                        component={date}
                        enablePast={true}
                        helperText="MM/DD/YYYY"
                        disableOpenOnEnter
                        disableFuture
                        // timezone={organization.timezone}
                      />
                      <ErrorMessage
                        name="dob"
                        component="span"
                        className={errorClass}
                      />
                    </Grid>
                  )} */}
                  {((this.multiplePatientsFound && this.multipleDobFound) ||
                    (this.noPatientFound && formProps.values.newPatient)) && (
                    <React.Fragment>
                      {/* {!(this.newPatient && formProps.values.newPatient) && (
                      <Hidden>
                        <Grid item xs={false} sm={6} />
                      </Hidden>
                    )} */}
                      {!this.noPatientFound && (
                        <Grid item xs={12}>
                          <p>
                            More information needed. Enter your phone number or
                            email.
                          </p>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <Field
                          classes={classes.paper}
                          name="phoneNumber"
                          component={phone}
                          label="Phone Number"
                          variant="outlined"
                          fullWidth
                          autoComplete="new-phoneNumber"
                          required
                        />
                        <ErrorMessage
                          name="phoneNumber"
                          component="span"
                          className={errorClass}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          classes={classes.paper}
                          name="email"
                          component={email}
                          label="Email"
                          variant="outlined"
                          fullWidth
                          placeholder="Email Address"
                          autoComplete="new-email"
                          required
                        />
                        <ErrorMessage
                          name="email"
                          component="span"
                          className={errorClass}
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                  {this.noPatientFound && !this.noNewPatientsAllowed && (
                    <Grid item xs={12}>
                      <Field
                        classes={classes}
                        name="newPatient"
                        component={singleCheckbox}
                        label="Are you a new patient and have not signed up?"
                        // required
                      />
                      <FormHelperText style={{ fontSize: "1em" }}>
                        If you are an existing patient, please check that you
                        entered your information correctly
                      </FormHelperText>
                      <ErrorMessage
                        name="newPatient"
                        component="span"
                        className={errorClass}
                      />
                    </Grid>
                  )}
                  {/* {this.noPatientFound && formProps.values.newPatient && (
                  <Grid item xs={12}>
                    <p>
                      More information needed. Enter your date of birth and your
                      preferred method of contact.
                    </p>
                  </Grid>
                )} */}
                  <Grid item xs={12}>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                      onChange={this.handleCaptchaSuccess}
                      onExpired={this.handleCaptchaExpired}
                      onErrored={this.handleCaptchaError}
                    />
                  </Grid>
                  <Hidden mdUp>
                    <Field
                      name="submit"
                      component={renderSubmitButton}
                      buttonWidth="100%"
                      disabled={
                        !this.state.captchaSuccess ||
                        (this.error ? true : false)
                      }
                    >
                      Next
                    </Field>
                  </Hidden>
                  <Hidden smDown>
                    <Field
                      name="submit"
                      component={renderSubmitButton}
                      disabled={
                        !this.state.captchaSuccess ||
                        (this.error ? true : false)
                      }
                    >
                      Next
                    </Field>
                  </Hidden>
                  {this.noPatientFound && this.noNewPatientsAllowed && (
                    <Grid item xs={12}>
                      <p className={errorClass}>
                        You must already be registered as a patient in order to
                        complete this action. If you have already registered,
                        please check that you entered your information
                        correctly.
                      </p>
                    </Grid>
                  )}
                  {!this.noPatientFound && this.noUnconfirmedMembersAllowed && (
                    <Grid item xs={12}>
                      <p className={errorClass}>
                        Your membership is still pending. Please contact us at{" "}
                        <a href={`tel:${this.props.organization.phoneNumber}`}>
                          {this.props.organization.phoneNumber}
                        </a>{" "}
                        in order to schedule an appointment.
                      </p>
                    </Grid>
                  )}
                  {this.error && (
                    <Grid item xs={12}>
                      <p className={errorClass}>
                        {this.error} Please contact us for assistance at{" "}
                        <a href={`tel:${this.props.organization.phoneNumber}`}>
                          {this.props.organization.phoneNumber}
                        </a>
                        .
                      </p>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    );
  }
}

const mapStateToProps = state => {
  return {
    organization: state.settings.organization,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedPatient: (patient, newPatient) =>
      dispatch({ type: SET_PATIENT, patient: patient, newPatient: newPatient }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar(PatientForm)));

import React from "react";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Select from "react-select";
import { Async, AsyncCreatable } from "react-select";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import MenuList from "@material-ui/core/MenuList";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import VirtualizedList from "../../components/VirtualizedList";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  Calendar,
} from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import {
  shiftPickerDateToTimezoneDate,
  shiftTimezoneDateToPickerDate,
} from "../../utils/math";
import { FormGroup } from "@material-ui/core";
import { isArray } from "lodash";

const selectStyles = {
  menu: base => ({
    ...base,
    zIndex: 100,
  }),
};

export const string = ({
  classes,
  label,
  placeholder,
  form: { touched, invalid, error },
  fullWidth,
  field,
  type,
  options,
  autoComplete,
  helpertext,
  required,
  ...custom
}) => (
  <React.Fragment>
    <label
      className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ${
        required ? "required" : ""
      }`}
      htmlFor={field.name}
    >
      {label}
    </label>
    <input
      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      id={field.name}
      {...field}
      type={type}
      placeholder={placeholder}
      error={touched && invalid}
      // helpertext={touched && error}
      autoComplete={autoComplete ? autoComplete : "off"}
      fullwidth={fullWidth ? "true" : "false"}
      {...custom}
    />
    {helpertext && <p className="text-gray-600 text-xs italic">{helpertext}</p>}
  </React.Fragment>
);

export const text = ({
  classes,
  label,
  placeholder,
  form: { touched, invalid, error },
  fullWidth,
  field,
  type,
  options,
  autoComplete,
  required,
  ...custom
}) => (
  <React.Fragment>
    <label
      className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ${
        required ? "required" : ""
      }`}
      htmlFor={field.name}
    >
      {label}
    </label>
    <textarea
      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      id={field.name}
      {...field}
      type={type}
      placeholder={placeholder}
      error={touched && invalid}
      helpertext={touched && error}
      autoComplete={autoComplete ? autoComplete : "off"}
      fullwidth={fullWidth ? "true" : "false"}
      required={required ? required : false}
      {...custom}
    />
  </React.Fragment>
);

export const email = ({
  classes,
  label,
  placeholder,
  form: { touched, invalid, error },
  fullWidth,
  field,
  type,
  options,
  autoComplete,
  required,
  ...custom
}) => (
  <React.Fragment>
    <label
      className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ${
        required ? "required" : ""
      }`}
      htmlFor={field.name}
    >
      {label}
    </label>
    <input
      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      id={field.name}
      {...field}
      type="email"
      placeholder={placeholder}
      error={touched && invalid}
      helpertext={touched && error}
      autoComplete={autoComplete ? autoComplete : "off"}
      fullwidth={fullWidth ? "true" : "false"}
      {...custom}
      required={required}
    />
  </React.Fragment>
);

export const decimal = ({
  classes,
  label,
  placeholder,
  form: { touched, invalid, error },
  fullWidth,
  field,
  type,
  options,
  autoComplete,
  required,
  ...custom
}) => (
  <React.Fragment>
    <label
      className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ${
        required ? "required" : ""
      }`}
      htmlFor={field.name}
    >
      {label}
    </label>
    <input
      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      id={field.name}
      {...field}
      type="number"
      step="any"
      placeholder={placeholder}
      error={touched && invalid}
      helpertext={touched && error}
      autoComplete={autoComplete ? autoComplete : "off"}
      fullwidth={fullWidth ? "true" : "false"}
      {...custom}
    />
  </React.Fragment>
);

export const integer = ({
  classes,
  label,
  placeholder,
  form: { touched, invalid, error },
  fullWidth,
  field,
  type,
  options,
  autoComplete,
  required,
  ...custom
}) => (
  <React.Fragment>
    <label
      className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ${
        required ? "required" : ""
      }`}
      htmlFor={field.name}
    >
      {label}
    </label>
    <input
      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      id={field.name}
      {...field}
      type="number"
      step="1"
      placeholder={placeholder}
      error={touched && invalid}
      helpertext={touched && error}
      autoComplete={autoComplete ? autoComplete : "off"}
      fullwidth={fullWidth ? "true" : "false"}
      {...custom}
    />
  </React.Fragment>
);

export const phone = ({
  classes,
  label,
  placeholder,
  form: { touched, invalid, error },
  field,
  options,
  autoComplete,
  required,
  ...custom
}) => (
  <React.Fragment>
    <label
      className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ${
        required ? "required" : ""
      }`}
      htmlFor={field.name}
    >
      {label}
    </label>
    <PhoneMask
      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      id={field.name}
      {...field}
      type="text"
      placeholder={placeholder}
      error={touched && invalid}
      helperText={touched && error}
      autoComplete={autoComplete ? autoComplete : "off"}
      {...custom}
      required={required}
    />
  </React.Fragment>
);

const PhoneMask = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      guide={false}
      // ref={inputRef}
      keepCharPositions={true}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
};

export const renderDateTextField = ({
  classes,
  label,
  placeholder,
  form: { touched, invalid, error, setFieldValue },
  field,
  ...custom
}) => (
  <TextField
    {...field}
    label={label}
    placeholder={placeholder}
    error={touched && invalid}
    helperText={touched && error}
    {...custom}
    className={classes.textField}
    InputLabelProps={{
      shrink: true,
    }}
    InputProps={{
      inputComponent: DateMask,
    }}
  />
);

const DateMask = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      guide={false}
      // ref={inputRef}
      keepCharPositions={true}
      mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
};

export const renderPhotoField = ({
  classes,
  label,
  buttonText,
  type,
  accept,
  field,
  id,
  ...custom
}) => (
  <div>
    <input
      classes={classes.input}
      {...custom}
      {...field}
      value=""
      label={label}
      type={type}
      accept={accept}
      style={{ display: "none" }}
      id={id}
    />
    <label htmlFor={id}>
      <Button {...custom} component="span" className={classes.button}>
        {buttonText}
      </Button>
    </label>
  </div>
);

renderPhotoField.propTypes = {
  id: PropTypes.string.isRequired,
};

export const renderTimeField = ({
  classes,
  label,
  placeholder,
  form: { touched, invalid, error },
  field: { value, ...rest },
  options,
  ...custom
}) => (
  <TextField
    {...rest}
    label={label}
    {...custom}
    className={classes.textField}
    InputLabelProps={{
      shrink: true,
    }}
    inputProps={{
      step: 300, // 5 min
    }}
    type="time"
  />
);

export const dateTime = ({ field, form, timezone, enablePast, ...custom }) => {
  const currentError = form.errors[field.name];
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DateTimePicker
        keyboard
        disablePast={typeof enablePast === "boolean" ? !enablePast : null}
        name={field.name}
        value={shiftTimezoneDateToPickerDate(field.value, timezone)}
        format="MM/DD/YYYY hh:mm A"
        helperText={
          currentError ? currentError : `Times in ${timezone} Timezone`
        }
        error={Boolean(currentError)}
        onError={(_, error) => form.setFieldError(field.name, error)}
        onChange={date => {
          form.setFieldValue(
            field.name,
            moment(shiftPickerDateToTimezoneDate(date, timezone))
              .utc()
              .format(),
            true
          );
        }}
        {...custom}
      />
    </MuiPickersUtilsProvider>
  );
};

export const date = ({
  field,
  form,
  timezone,
  autoComplete,
  enablePast,
  helperText,
  label,
  required,
  ...custom
}) => {
  const currentError = form.errors[field.name];
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <label
        className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ${
          required ? "required" : ""
        }`}
        htmlFor={field.name}
      >
        {label}
      </label>
      <DatePicker
        keyboard
        disablePast={typeof enablePast === "boolean" ? !enablePast : null}
        disableFuture={typeof enablePast === "boolean" ? enablePast : null}
        autoOk
        className={
          "appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none"
        }
        name={field.name}
        autoComplete={autoComplete ? autoComplete : "off"}
        value={field.value ? field.value : null} //{shiftTimezoneDateToPickerDate(field.value, timezone)}
        format="MM/DD/YYYY"
        // helperText={
        //   currentError ? currentError : `Dates in ${timezone} Timezone`
        // }
        mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
        error={Boolean(currentError)}
        onError={(_, error) => form.setFieldError(field.name, error)}
        onChange={date => {
          form.setFieldValue(
            field.name,
            // moment(shiftPickerDateToTimezoneDate(date, timezone))
            // .utc()
            moment(date, "MM/DD/YYYY").format("YYYY-MM-DD"),
            true
          );
        }}
        {...custom}
      />
      <p className="text-gray-600 text-xs italic">{helperText}</p>
    </MuiPickersUtilsProvider>
  );
};

export const year = ({
  field,
  form,
  timezone,
  autoComplete,
  enablePast,
  helperText,
  label,
  required,
  ...custom
}) => {
  const currentError = form.errors[field.name];
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <label
        className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ${
          required ? "required" : ""
        }`}
        htmlFor={field.name}
      >
        {label}
      </label>
      <DatePicker
        keyboard
        disablePast={typeof enablePast === "boolean" ? !enablePast : null}
        disableFuture={typeof enablePast === "boolean" ? enablePast : null}
        autoOk
        className={
          "appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none"
        }
        name={field.name}
        autoComplete={autoComplete ? autoComplete : "off"}
        value={field.value ? field.value : null} //{shiftTimezoneDateToPickerDate(field.value, timezone)}
        format="YYYY"
        views={["year"]}
        // helperText={
        //   currentError ? currentError : `Dates in ${timezone} Timezone`
        // }
        mask={[/\d/, /\d/, /\d/, /\d/]}
        error={Boolean(currentError)}
        onError={(_, error) => form.setFieldError(field.name, error)}
        onChange={date => {
          form.setFieldValue(
            field.name,
            // moment(shiftPickerDateToTimezoneDate(date, timezone))
            // .utc()
            moment(date, "MM/DD/YYYY").format("YYYY-MM-DD"),
            true
          );
        }}
        {...custom}
      />
      <p className="text-gray-600 text-xs italic">{helperText}</p>
    </MuiPickersUtilsProvider>
  );
};

export const renderCalendarPicker = ({
  field,
  form,
  date,
  autoComplete,
  enablePast = false,
  ...custom
}) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Calendar
        // keyboard
        disablePast={!enablePast}
        autoOk
        date={date}
        name={field.name}
        autoComplete={autoComplete ? autoComplete : "off"}
        format="MM/DD/YYYY"
        {...custom}
      />
    </MuiPickersUtilsProvider>
  );
};

export const singleCheckbox = ({
  field: { value, ...rest },
  label,
  classes,
  placeholder,
  form: { touched, invalid, error },
  defaultValue,
  required,
  ...custom
}) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox
          {...rest}
          className={classes.checkbox}
          checked={value != null ? value : defaultValue ? defaultValue : false}
          {...custom}
        />
      }
      label={label}
    />
  </div>
);

export const checkbox = ({
  field,
  options,
  defaultValue,
  label,
  required = false,
  disabled = false,
  form: { touched, error, setFieldValue, setFieldTouched },
  ...rest
}) => {
  const isChecked = value => {
    return (
      field &&
      field.value &&
      isArray(field.value) &&
      field.value.some(opt => opt.value === value)
    );
  };

  return (
    <FormControl
      name={field.name}
      required={required}
      error={null}
      component="fieldset"
      style={{ display: "block" }}
    >
      <label
        className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ${
          required ? "required" : ""
        }`}
        htmlFor={field.name}
      >
        {label}
      </label>
      <FormGroup
        row
        {...field}
        defaultValue={defaultValue}
        onChange={event => {
          const value = event.target.value;
          let currentSelectedOptions = field.value;
          if (!isArray(currentSelectedOptions)) {
            currentSelectedOptions = [];
          }

          let selectedOption;
          if (Array.isArray(options) && options.length > 0) {
            selectedOption = options.find(opt => opt.value === value);
          }
          if (selectedOption) {
            if (
              currentSelectedOptions.some(
                opt => opt.value === selectedOption.value
              )
            ) {
              currentSelectedOptions = [
                ...currentSelectedOptions.filter(
                  opt => opt.value !== selectedOption.value
                ),
              ];
            } else {
              currentSelectedOptions.push(selectedOption);
            }

            setFieldValue(
              field.name,
              currentSelectedOptions.length <= 0 ? null : currentSelectedOptions
            );
          }
        }}
      >
        {Array.isArray(options) && options.length > 0
          ? options.map(opt => {
              return (
                <FormControlLabel
                  key={opt.label}
                  label={opt.label}
                  value={opt.value}
                  checked={isChecked(opt.value)}
                  control={
                    <Checkbox
                      disabled={disabled}
                      checked={isChecked(opt.value)}
                      color={"primary"}
                    />
                  }
                />
              );
            })
          : null}
      </FormGroup>
      <p></p>
    </FormControl>
  );
};

export const boolean = ({
  field,
  options,
  defaultValue,
  label,
  required = false,
  disabled = false,
  ...rest
}) => (
  <FormControl name={field.name}>
    <label
      className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ${
        required ? "required" : ""
      }`}
      htmlFor={field.name}
    >
      {label}
    </label>
    <RadioGroup row {...field} defaultValue={defaultValue}>
      {Array.isArray(options) && options.length > 0
        ? options.map(opt => {
            return (
              <FormControlLabel
                key={opt.label}
                label={opt.label}
                value={opt.value}
                control={<Radio disabled={disabled} required={required} />}
              />
            );
          })
        : null}
    </RadioGroup>
  </FormControl>
);

export const radio = ({
  field,
  options,
  defaultValue,
  label,
  required = false,
  disabled = false,
  form: { touched, error, setFieldValue, setFieldTouched },
  ...rest
}) => (
  <FormControl name={field.name} style={{ display: "block" }}>
    <label
      className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ${
        required ? "required" : ""
      }`}
      htmlFor={field.name}
    >
      {label}
    </label>
    <RadioGroup
      row
      {...field}
      defaultValue={defaultValue}
      onChange={(event, value) => {
        let selectedOption;
        if (Array.isArray(options) && options.length > 0) {
          options.some(opt => {
            if (opt.value === value) {
              selectedOption = opt;
              return true;
            }
            return false;
          });
        }
        if (selectedOption) {
          setFieldValue(field.name, selectedOption);
        } else {
          setFieldValue(field.name, value);
        }
      }}
    >
      {Array.isArray(options) && options.length > 0
        ? options.map(opt => {
            return (
              <FormControlLabel
                key={opt.label}
                label={opt.label}
                value={opt.value}
                checked={field.value && opt.value === field.value.value}
                control={
                  <Radio
                    disabled={disabled}
                    required={required}
                    checked={field.value && opt.value === field.value.value}
                    color={"primary"}
                  />
                }
              />
            );
          })
        : null}
      {field.value && (
        <Button
          style={{ fontSize: "70%", color: "#999", marginTop: "4px" }}
          onClick={() => setFieldValue(field.name, null)}
        >
          Clear
        </Button>
      )}
    </RadioGroup>
  </FormControl>
);

export const renderFormHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>;
  }
};

export const dropdown = ({
  selected,
  options,
  disabled,
  label,
  field,
  isMulti,
  form: { touched, error, setFieldValue, setFieldTouched },
  defaultValue,
  helpertext,
  required,
  ...custom
}) => (
  <div>
    <label
      className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ${
        required ? "required" : ""
      }`}
      htmlFor={field.name}
    >
      {label}
    </label>
    <Select
      {...field}
      onChange={value => setFieldValue(field.name, value)}
      onBlur={() => setFieldTouched(field.name, true)}
      defaultValue={defaultValue}
      value={isMulti ? field.values : field.value}
      options={options}
      isDisabled={disabled}
      isMulti={isMulti}
      isClearable={true}
      required={required}
      components={options.length ? { VirtualizedList } : MenuList}
      {...custom}
      styles={{
        menu: base => ({
          ...base,
          zIndex: 100,
        }),
      }}
    />
    <p className="text-gray-600 text-xs italic">{helpertext}</p>
    {/* {renderFormHelper({ touched, error })} */}
  </div>
);

export const creatable = ({
  selected,
  options,
  isDisabled,
  label,
  field,
  form: { touched, error, setFieldValue, setFieldTouched },
  defaultValue,
  loadOptions,
  helpertext,
  required,
  ...custom
}) => (
  <div>
    <label
      className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ${
        required ? "required" : ""
      }`}
      htmlFor={field.name}
    >
      {label}
    </label>
    <AsyncCreatable
      {...field}
      onChange={value => setFieldValue(field.name, value)}
      onBlur={() => setFieldTouched(field.name, true)}
      defaultValue={defaultValue}
      // value={field.values}
      defaultOptions={options}
      loadOptions={loadOptions}
      isDisabled={isDisabled}
      isClearable
      placeholder={""}
      blurInputOnSelect={true}
      noOptionsMessage={() => null}
      // cacheOptions
      styles={selectStyles}
      components={
        Array.isArray(options) && options.length > 0
          ? { VirtualizedList }
          : {
              VirtualizedList,
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }
      }
      {...custom}
      // styles={{
      //   menu: base => ({
      //     ...base,
      //     zIndex: 100,
      //   }),
      // }}
    />
    <p className="text-gray-600 text-xs italic">{helpertext}</p>
  </div>
);

export const async = ({
  selected,
  options,
  disabled,
  defaultValue,
  label,
  field,
  form: { touched, error, setFieldValue, setFieldTouched },
  loadOptions,
  helpertext,
  required,
  ...custom
}) => (
  <div>
    <label
      className={`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ${
        required ? "required" : ""
      }`}
      htmlFor={field.name}
    >
      {label}
    </label>
    <Async
      {...field}
      onChange={value => setFieldValue(field.name, value)}
      onBlur={() => setFieldTouched(field.name, true)}
      // defaultValue={defaultValue}
      // value={field.value}
      defaultOptions={options}
      isDisabled={disabled}
      {...custom}
      // styles={{
      //   menu: base => ({
      //     ...base,
      //     zIndex: 100,
      //   }),
      // }}
      components={
        Array.isArray(options) && options.length > 0
          ? null
          : { DropdownIndicator: () => null, IndicatorSeparator: () => null }
      }
      loadOptions={loadOptions}
      placeholder={""}
      blurInputOnSelect={true}
      noOptionsMessage={() => null}
      cacheOptions
      styles={selectStyles}
    />
    {/* {renderFormHelper({ touched, error })} */}
    <p className="text-gray-600 text-xs italic">{helpertext}</p>
  </div>
);

/* Example select field
  <Field
    classes={classes.paper}
    name="appointmentMonth"
    component={renderSelectField}
    label="Month"
    validate={required}
    options={monthOptions}
    selected={{ value: selectedMonth+1, label: months[selectedMonth] }}
  >
  </Field>
*/

import React from "react";
import { Grid } from "@material-ui/core";

const BootstrapContainerGrid = props => {
  const { ...custom } = props;
  return (
    <Grid container style={{ marginTop: "3em" }} {...custom}>
      {props.children}
    </Grid>
  );
};

export default BootstrapContainerGrid;

// action types
import {
  REQUEST_PATIENTS,
  FETCH_PATIENTS_SUCCEEDED,
  FETCH_PATIENTS_FAILED,
  SET_PATIENT,
  SET_PATIENTS_FETCHING,
  EDIT_PATIENTS,
  SET_PATIENT_CREATED,
} from "../actions/actions";

const initialState = {
  patients: [],
  error: null,
  selectedPatient: null,
  newPatient: false,
  patientCreated: false,
};

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PATIENTS: {
      state = { ...state, fetching: true, fetched: false, error: null };
      break;
    }
    case FETCH_PATIENTS_SUCCEEDED: {
      state = {
        ...state,
        fetching: false,
        fetched: true,
        patients: action.patients,
      };
      break;
    }
    case FETCH_PATIENTS_FAILED: {
      state = { ...state, fetching: false, fetched: true, error: action.error };
      break;
    }
    case SET_PATIENT: {
      state = {
        ...state,
        notes: [],
        goals: [],
        selectedPatient: action.patient,
        newPatient: action.newPatient,
      };
      break;
    }
    case SET_PATIENT_CREATED: {
      state = {
        ...state,
        patientCreated: action.patientCreated,
      };
      break;
    }
    case SET_PATIENTS_FETCHING: {
      state = { ...state, fetching: true, fetched: false, error: null };
      break;
    }
    case EDIT_PATIENTS: {
      let patients = [...state.patients];
      let patientIndex;
      patients.find((patient, index) => {
        if (patient.id === action.patient.id) {
          patientIndex = index;
          return true;
        }
      });
      if (patientIndex >= 0) {
        patients[patientIndex] = action.patient;
      } else {
        patients.push(action.patient);
      }
      state = {
        ...state,
        patients: patients,
      };
      break;
    }
    default: {
      break;
    }
  }
  return state;
};

export default patientReducer;

export const errorClass = "text-red-500 text-sm italic";

export const drugFrequencyOptions = [
  { value: "QD", label: "Every Day" },
  { value: "QOD", label: "Every Other Day" },
  { value: "Q1H", label: "Every Hour" },
  { value: "Q2H", label: "Every 2 Hours" },
  { value: "Q3H", label: "Every 3 Hours" },
  { value: "Q4H", label: "Every 4 Hours" },
  { value: "Q6H", label: "Every 6 Hours" },
  { value: "Q8H", label: "Every 8 Hours" },
  { value: "BID", label: "Two Times Daily" },
  { value: "TID", label: "Three Times Daily" },
  { value: "QID", label: "Four Times Daily" },
  { value: "AM", label: "Every AM" },
  { value: "PM", label: "Every PM" },
  { value: "BED", label: "At Bedtime" },
  { value: "WK", label: "Weekly" },
  { value: "MO", label: "Monthly" },
  { value: "PRN", label: "As Needed" },
  { value: "OTHER", label: "Other" },
];

export const stateOptions = [
  {
    label: "Alabama",
    value: "Alabama",
    abbreviation: "AL",
  },
  {
    label: "Alaska",
    value: "Alaska",
    abbreviation: "AK",
  },
  {
    label: "Arizona",
    value: "Arizona",
    abbreviation: "AZ",
  },
  {
    label: "Arkansas",
    value: "Arkansas",
    abbreviation: "AR",
  },
  {
    label: "California",
    value: "California",
    abbreviation: "CA",
  },
  {
    label: "Colorado",
    value: "Colorado",
    abbreviation: "CO",
  },
  {
    label: "Connecticut",
    value: "Connecticut",
    abbreviation: "CT",
  },
  {
    label: "Delaware",
    value: "Delaware",
    abbreviation: "DE",
  },
  {
    label: "District Of Columbia",
    value: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    label: "Florida",
    value: "Florida",
    abbreviation: "FL",
  },
  {
    label: "Georgia",
    value: "Georgia",
    abbreviation: "GA",
  },
  {
    label: "Hawaii",
    value: "Hawaii",
    abbreviation: "HI",
  },
  {
    label: "Idaho",
    value: "Idaho",
    abbreviation: "ID",
  },
  {
    label: "Illinois",
    value: "Illinois",
    abbreviation: "IL",
  },
  {
    label: "Indiana",
    value: "Indiana",
    abbreviation: "IN",
  },
  {
    label: "Iowa",
    value: "Iowa",
    abbreviation: "IA",
  },
  {
    label: "Kansas",
    value: "Kansas",
    abbreviation: "KS",
  },
  {
    label: "Kentucky",
    value: "Kentucky",
    abbreviation: "KY",
  },
  {
    label: "Louisiana",
    value: "Louisiana",
    abbreviation: "LA",
  },
  {
    label: "Maine",
    value: "Maine",
    abbreviation: "ME",
  },
  {
    label: "Maryland",
    value: "Maryland",
    abbreviation: "MD",
  },
  {
    label: "Massachusetts",
    value: "Massachusetts",
    abbreviation: "MA",
  },
  {
    label: "Michigan",
    value: "Michigan",
    abbreviation: "MI",
  },
  {
    label: "Minnesota",
    value: "Minnesota",
    abbreviation: "MN",
  },
  {
    label: "Mississippi",
    value: "Mississippi",
    abbreviation: "MS",
  },
  {
    label: "Missouri",
    value: "Missouri",
    abbreviation: "MO",
  },
  {
    label: "Montana",
    value: "Montana",
    abbreviation: "MT",
  },
  {
    label: "Nebraska",
    value: "Nebraska",
    abbreviation: "NE",
  },
  {
    label: "Nevada",
    value: "Nevada",
    abbreviation: "NV",
  },
  {
    label: "New Hampshire",
    value: "New Hampshire",
    abbreviation: "NH",
  },
  {
    label: "New Jersey",
    value: "New Jersey",
    abbreviation: "NJ",
  },
  {
    label: "New Mexico",
    value: "New Mexico",
    abbreviation: "NM",
  },
  {
    label: "New York",
    value: "New York",
    abbreviation: "NY",
  },
  {
    label: "North Carolina",
    value: "North Carolina",
    abbreviation: "NC",
  },
  {
    label: "North Dakota",
    value: "North Dakota",
    abbreviation: "ND",
  },
  {
    label: "Ohio",
    value: "Ohio",
    abbreviation: "OH",
  },
  {
    label: "Oklahoma",
    value: "Oklahoma",
    abbreviation: "OK",
  },
  {
    label: "Oregon",
    value: "Oregon",
    abbreviation: "OR",
  },
  {
    label: "Pennsylvania",
    value: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    label: "Rhode Island",
    value: "Rhode Island",
    abbreviation: "RI",
  },
  {
    label: "South Carolina",
    value: "South Carolina",
    abbreviation: "SC",
  },
  {
    label: "South Dakota",
    value: "South Dakota",
    abbreviation: "SD",
  },
  {
    label: "Tennessee",
    value: "Tennessee",
    abbreviation: "TN",
  },
  {
    label: "Texas",
    value: "Texas",
    abbreviation: "TX",
  },
  {
    label: "Utah",
    value: "Utah",
    abbreviation: "UT",
  },
  {
    label: "Vermont",
    value: "Vermont",
    abbreviation: "VT",
  },
  {
    label: "Virgin Islands",
    value: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    label: "Virginia",
    value: "Virginia",
    abbreviation: "VA",
  },
  {
    label: "Washington",
    value: "Washington",
    abbreviation: "WA",
  },
  {
    label: "West Virginia",
    value: "West Virginia",
    abbreviation: "WV",
  },
  {
    label: "Wisconsin",
    value: "Wisconsin",
    abbreviation: "WI",
  },
  {
    label: "Wyoming",
    value: "Wyoming",
    abbreviation: "WY",
  },
];

export const relationshipTypes = [
  {
    value: "C",
    system: "http://terminology.hl7.org/CodeSystem/v2-0131",
    label: "Emergency Contact",
    description: "",
  },
  {
    value: "E",
    system: "http://terminology.hl7.org/CodeSystem/v2-0131",
    label: "Employer",
    description: "",
  },
  {
    value: "F",
    system: "http://terminology.hl7.org/CodeSystem/v2-0131",
    label: "Federal Agency",
    description: "",
  },
  {
    value: "I",
    system: "http://terminology.hl7.org/CodeSystem/v2-0131",
    label: "Insurance Company",
    description: "",
  },
  {
    value: "N",
    system: "http://terminology.hl7.org/CodeSystem/v2-0131",
    label: "Next-of-Kin",
    description: "",
  },
  {
    value: "O",
    system: "http://terminology.hl7.org/CodeSystem/v2-0131",
    label: "Other",
    description: "",
  },
  {
    value: "S",
    system: "http://terminology.hl7.org/CodeSystem/v2-0131",
    label: "State Agency",
    description: "",
  },
  {
    value: "U",
    system: "http://terminology.hl7.org/CodeSystem/v2-0131",
    label: "Unknown",
    description: "",
  },
  {
    value: "FAMMEMB",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "family member",
    description:
      'A relationship between two people characterizing their "familial" relationship',
  },
  {
    value: "CHILD",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "child",
    description: "A child of the person.",
  },
  {
    value: "CHLDADOPT",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "adopted child",
    description:
      "A child taken into a family through legal means and raised by the patient (parent) as his or her own child.",
  },
  {
    value: "DAUADOPT",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "adopted daughter",
    description:
      "A female child taken into a family through legal means and raised by the patient (parent) as his or her own child.",
  },
  {
    value: "SONADOPT",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "adopted son",
    description:
      "A male child taken into a family through legal means and raised by the patient (parent) as his or her own child.",
  },
  {
    value: "CHLDFOST",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "foster child",
    description:
      "A child receiving parental care and nurture from the patient (parent) but not related to him or her through legal or blood ties.",
  },
  {
    value: "DAUFOST",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "foster daughter",
    description:
      "A female child receiving parental care and nurture from the patient (parent) but not related to him or her through legal or blood ties.",
  },
  {
    value: "SONFOST",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "foster son",
    description:
      "A male child receiving parental care and nurture from the patient (parent) but not related to him or her through legal or blood ties.",
  },
  {
    value: "DAUC",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "daughter",
    description: " A female child (of any legal type) of the person (parent)",
  },
  {
    value: "DAU",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "natural daughter",
    description: "A female offspring of the person (parent).",
  },
  {
    value: "STPDAU",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "stepdaughter",
    description: "A daughter of the person's spouse by a previous union.",
  },
  {
    value: "NCHILD",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "natural child",
    description: "An offspring of the person as determined by birth.",
  },
  {
    value: "SON",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "natural son",
    description: "A male offspring of the person (parent).",
  },
  {
    value: "SONC",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "son",
    description: " A male child (of any type) of person (parent)",
  },
  {
    value: "STPSON",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "stepson",
    description: "A son of the person's spouse by a previous union.",
  },
  {
    value: "STPCHLD",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "step child",
    description: "A child of the person's spouse by a previous union.",
  },
  {
    value: "EXT",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "extended family member",
    description:
      " A family member not having an immediate genetic or legal relationship e.g. Aunt, cousin, great grandparent, grandchild, grandparent, niece, nephew or uncle.",
  },
  {
    value: "AUNT",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "aunt",
    description: "A sister of the person's mother or father.",
  },
  {
    value: "MAUNT",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "maternal aunt",
    description: "A biological sister of the person's biological mother.",
  },
  {
    value: "PAUNT",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "paternal aunt",
    description: "A biological sister of the person's biological father.",
  },
  {
    value: "COUSN",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "cousin",
    description:
      "A relative of the patient descended from a common ancestor, such as a grandparent, by two or more steps in a diverging line.",
  },
  {
    value: "MCOUSN",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "maternal cousin",
    description:
      "A biological relative of the patient descended from a common ancestor on the patient's mother's side, such as a grandparent, by two or more steps in a diverging line.",
  },
  {
    value: "PCOUSN",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "paternal cousin",
    description:
      "A biological relative of the patient descended from a common ancestor on the patient's father's side, such as a grandparent, by two or more steps in a diverging line.",
  },
  {
    value: "GGRPRN",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "great grandparent",
    description: "A parent of the person's grandparent.",
  },
  {
    value: "GGRFTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "great grandfather",
    description: "The father of the person's grandparent.",
  },
  {
    value: "MGGRFTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "maternal great-grandfather",
    description:
      "The biological father of the person's biological mother's parent.",
  },
  {
    value: "PGGRFTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "paternal great-grandfather",
    description:
      "The biological father of the person's biological father's parent.",
  },
  {
    value: "GGRMTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "great grandmother",
    description: "The mother of the person's grandparent.",
  },
  {
    value: "MGGRMTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "maternal great-grandmother",
    description:
      "The biological mother of the person's biological mother's parent.",
  },
  {
    value: "PGGRMTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "paternal great-grandmother",
    description:
      "The biological mother of the person's biological father's parent.",
  },
  {
    value: "MGGRPRN",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "maternal great-grandparent",
    description:
      "A biological parent of the person's biological mother's parent.",
  },
  {
    value: "PGGRPRN",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "paternal great-grandparent",
    description:
      "A biological parent of the person's biological father's parent.",
  },
  {
    value: "GRNDCHILD",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "grandchild",
    description: "A child of the person's son or daughter.",
  },
  {
    value: "GRNDDAU",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "granddaughter",
    description: "A daughter of the person's son or daughter.",
  },
  {
    value: "GRNDSON",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "grandson",
    description: "A son of the person's son or daughter.",
  },
  {
    value: "GRPRN",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "grandparent",
    description: "A parent of the person's mother or father.",
  },
  {
    value: "GRFTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "grandfather",
    description: "The father of the person's mother or father.",
  },
  {
    value: "MGRFTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "maternal grandfather",
    description: "The biological father of the person's biological mother.",
  },
  {
    value: "PGRFTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "paternal grandfather",
    description: "The biological father of the person's biological father.",
  },
  {
    value: "GRMTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "grandmother",
    description: "The mother of the person's mother or father.",
  },
  {
    value: "MGRMTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "maternal grandmother",
    description: "The biological mother of the person's biological mother.",
  },
  {
    value: "PGRMTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "paternal grandmother",
    description: "The biological mother of the person's biological father.",
  },
  {
    value: "MGRPRN",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "maternal grandparent",
    description: "The biological parent of the person's biological mother.",
  },
  {
    value: "PGRPRN",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "paternal grandparent",
    description: "The biological parent of the person's biological father.",
  },
  {
    value: "INLAW",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "inlaw",
    description:
      "A relationship between an individual and a member of their spousal partner's immediate family.",
  },
  {
    value: "CHLDINLAW",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "child-in-law",
    description: "The spouse of person's child.",
  },
  {
    value: "DAUINLAW",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "daughter in-law",
    description: "The wife of person's son.",
  },
  {
    value: "SONINLAW",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "son in-law",
    description: "The husband of person's daughter.",
  },
  {
    value: "PRNINLAW",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "parent in-law",
    description: "The parent of person's husband or wife.",
  },
  {
    value: "FTHINLAW",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "father-in-law",
    description: "The father of the person's husband or wife.",
  },
  {
    value: "MTHINLAW",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "mother-in-law",
    description: "The mother of the person's husband or wife.",
  },
  {
    value: "SIBINLAW",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "sibling in-law",
    description:
      "(1) a sibling of the person's spouse, or (2) the spouse of the person's sibling, or (3) the spouse of a sibling of the person's spouse.",
  },
  {
    value: "BROINLAW",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "brother-in-law",
    description:
      "(1) a brother of the person's spouse, or (2) the husband of the person's sister, or (3) the husband of a sister of the person's spouse.",
  },
  {
    value: "SISINLAW",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "sister-in-law",
    description:
      "(1) a sister of the person's spouse, or (2) the wife of the person's brother, or (3) the wife of a brother of the person's spouse.",
  },
  {
    value: "NIENEPH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "niece/nephew",
    description:
      "A child of person's brother or sister or of the brother or sister of the person's spouse.",
  },
  {
    value: "NEPHEW",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "nephew",
    description:
      "A son of the person's brother or sister or of the brother or sister of the person's spouse.",
  },
  {
    value: "NIECE",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "niece",
    description:
      "A daughter of the person's brother or sister or of the brother or sister of the person's spouse.",
  },
  {
    value: "UNCLE",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "uncle",
    description: "A brother of the person's mother or father.",
  },
  {
    value: "MUNCLE",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "maternal uncle",
    description: "A biological brother of the person's biological mother.",
  },
  {
    value: "PUNCLE",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "paternal uncle",
    description: "A biological brother of the person's biological father.",
  },
  {
    value: "PRN",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "parent",
    description:
      "One who begets, gives birth to, or nurtures and raises the child.",
  },
  {
    value: "ADOPTP",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "adoptive parent",
    description:
      "This person (parent) has taken the patient (child) into their family through legal means and raises them as his or her own child.",
  },
  {
    value: "ADOPTF",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "adoptive father",
    description:
      "This person (father) is a male who has taken the patient (child) into their family through legal means and raises them as his own child.",
  },
  {
    value: "ADOPTM",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "adoptive mother",
    description:
      "This person (mother) is a female who has taken the patient (child) into their family through legal means and raises them as her own child.",
  },
  {
    value: "FTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "father",
    description: "A male who begets or raises or nurtures the child.",
  },
  {
    value: "FTHFOST",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "foster father",
    description:
      "This person (parent) who is a male state-certified caregiver responsible for the patient (child) who has been placed in the parent's care. The placement of the child is usually arranged through the government or a social-service agency, and temporary. The state, via a jurisdiction recognized child protection agency, stands as in loco parentis to the child, making all legal decisions while the foster parent is responsible for the day-to-day care of the specified child.",
  },
  {
    value: "NFTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "natural father",
    description: "A male who begets the child.",
  },
  {
    value: "NFTHF",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "natural father of fetus",
    description: "Indicates the biologic male parent of a fetus.",
  },
  {
    value: "STPFTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "stepfather",
    description:
      "The husband of person's mother and not the person's natural father.",
  },
  {
    value: "MTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "mother",
    description:
      "A female who conceives, gives birth to, or raises and nurtures the child.",
  },
  {
    value: "GESTM",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "gestational mother",
    description:
      "This person is a female whose womb carries the fetus of the patient. Generally used when the gestational mother and natural mother are not the same.",
  },
  {
    value: "MTHFOST",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "foster mother",
    description:
      "This person (parent) who is a female state-certified caregiver responsible for the patient (child) who has been placed in the parent's care. The placement of the child is usually arranged through the government or a social-service agency, and temporary. The state, via a jurisdiction recognized child protection agency, stands as in loco parentis to the child, making all legal decisions while the foster parent is responsible for the day-to-day care of the specified child.",
  },
  {
    value: "NMTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "natural mother",
    description: "A female who conceives or gives birth to the child.",
  },
  {
    value: "NMTHF",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "natural mother of fetus",
    description: "This person is the biologic female parent of the fetus.",
  },
  {
    value: "STPMTH",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "stepmother",
    description:
      "The wife of person's father and not the person's natural mother.",
  },
  {
    value: "NPRN",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "natural parent",
    description: "natural parent",
  },
  {
    value: "PRNFOST",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "foster parent",
    description:
      "This person (parent) who is a state-certified caregiver responsible for the patient (child) who has been placed in the parent's care. The placement of the child is usually arranged through the government or a social-service agency, and temporary. The state, via a jurisdiction recognized child protection agency, stands as in loco parentis to the child, making all legal decisions while the foster parent is responsible for the day-to-day care of the specified child.",
  },
  {
    value: "STPPRN",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "step parent",
    description:
      "The spouse of the person's parent and not the person's natural parent.",
  },
  {
    value: "SIB",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "sibling",
    description:
      "This person shares one or both parents in common with the person.",
  },
  {
    value: "BRO",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "brother",
    description:
      "A male sharing one or both parents in common with the person.",
  },
  {
    value: "HBRO",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "half-brother",
    description:
      "A male related to the person by sharing only one biological parent.",
  },
  {
    value: "NBRO",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "natural brother",
    description: "A male having the same biological parents as the person.",
  },
  {
    value: "TWINBRO",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "twin brother",
    description:
      "This person was carried in the same womb as the male patient and shares common biological parents.",
  },
  {
    value: "FTWINBRO",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "fraternal twin brother",
    description:
      "This person was carried in the same womb as the male patient and shares common biological parents but is the product of a distinct egg/sperm pair.",
  },
  {
    value: "ITWINBRO",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "identical twin brother",
    description:
      "The male patient is an offspring of the same egg-sperm pair as the male patient.",
  },
  {
    value: "STPBRO",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "stepbrother",
    description: "A son of the person's stepparent.",
  },
  {
    value: "HSIB",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "half-sibling",
    description: "Related to the person by sharing only one biological parent.",
  },
  {
    value: "HSIS",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "half-sister",
    description:
      "A female related to the person by sharing only one biological parent.",
  },
  {
    value: "NSIB",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "natural sibling",
    description:
      "This person has both biological parents in common with the person.",
  },
  {
    value: "NSIS",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "natural sister",
    description: "A female having the same biological parents as the person.",
  },
  {
    value: "TWINSIS",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "twin sister",
    description:
      "This person was carried in the same womb as the female patient and shares common biological parents.",
  },
  {
    value: "FTWINSIS",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "fraternal twin sister",
    description:
      "This person was carried in the same womb as the female patient and shares common biological parents but is the product of a distinct egg/sperm pair.",
  },
  {
    value: "ITWINSIS",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "identical twin sister",
    description:
      "The female patient is an offspring of the same egg-sperm pair as the female patient.",
  },
  {
    value: "TWIN",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "twin",
    description:
      "This person and the patient were carried in the same womb and shared common biological parents.",
  },
  {
    value: "FTWIN",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "fraternal twin",
    description:
      "This person and the patient were carried in the same womb and share common biological parents but are the product of distinct egg/sperm pairs.",
  },
  {
    value: "ITWIN",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "identical twin",
    description:
      "This person and the patient are offspring of the same egg-sperm pair.",
  },
  {
    value: "SIS",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "sister",
    description:
      "A female sharing one or both parents in common with the person.",
  },
  {
    value: "STPSIS",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "stepsister",
    description: "A daughter of the person's stepparent.",
  },
  {
    value: "STPSIB",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "step sibling",
    description: "A child of the person's stepparent.",
  },
  {
    value: "SIGOTHR",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "significant other",
    description:
      "A person who is important to one's well being; especially a spouse or one in a similar relationship. (This person is the one who is important)",
  },
  {
    value: "DOMPART",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "domestic partner",
    description:
      "This person cohabits with the patient but is not the person's spouse.",
  },
  {
    value: "FMRSPS",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "former spouse",
    description:
      "This person was previously joined to the patient in marriage and this marriage is now dissolved and inactive. Usage Note: This is significant to indicate as some jurisdictions have different legal requirements for former spouse to access the patient's record, from a general friend.",
  },
  {
    value: "SPS",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "spouse",
    description: "A marriage partner of the patient.",
  },
  {
    value: "HUSB",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "husband",
    description: "A man joined to a woman (patient) in marriage.",
  },
  {
    value: "WIFE",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "wife",
    description: "A woman joined to a man (patient) in marriage.",
  },
  {
    value: "FRND",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "unrelated friend",
    description: "A person who is known, liked, and trusted by the patient.",
  },
  {
    value: "NBOR",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "neighbor",
    description: "This person lives near or next to the patient.",
  },
  {
    value: "ONESELF",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "self",
    description: "The relationship that a person has with his or her self.",
  },
  {
    value: "ROOM",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleCode",
    label: "Roommate",
    description: "One who shares living quarters with the subject.",
  },
  {
    value: "PROV",
    system: "http://terminology.hl7.org/CodeSystem/v3-RoleClass",
    label: "Healthcare Provider",
    description:
      "An Entity that is authorized to provide health care services.",
  },
];

export const languages = [
  {
    value: "aa",
    label: "Afar",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ab",
    label: "Abkhaz",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ae",
    label: "Avestan",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "af",
    label: "Afrikaans",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ak",
    label: "Akan",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "am",
    label: "Amharic",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "an",
    label: "Aragonese",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ar",
    label: "Arabic",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "as",
    label: "Assamese",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "av",
    label: "Avaric",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ay",
    label: "Aymara",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "az",
    label: "Azerbaijani",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ba",
    label: "Bashkir",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "be",
    label: "Belarusian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "bg",
    label: "Bulgarian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "bh",
    label: "Bihari",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "bi",
    label: "Bislama",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "bm",
    label: "Bambara",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "bn",
    label: "Bengali",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "bo",
    label: "Tibetan Standard",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "br",
    label: "Breton",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "bs",
    label: "Bosnian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ca",
    label: "Catalan",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ce",
    label: "Chechen",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ch",
    label: "Chamorro",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "co",
    label: "Corsican",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "cr",
    label: "Cree",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "cs",
    label: "Czech",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "cu",
    label: "Old Church Slavonic",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "cv",
    label: "Chuvash",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "cy",
    label: "Welsh",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "da",
    label: "Danish",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "de",
    label: "German",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "dv",
    label: "Divehi",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "dz",
    label: "Dzongkha",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ee",
    label: "Ewe",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "el",
    label: "Greek",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "en",
    label: "English",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "eo",
    label: "Esperanto",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "es",
    label: "Spanish",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "et",
    label: "Estonian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "eu",
    label: "Basque",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "fa",
    label: "Persian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ff",
    label: "Fula",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "fi",
    label: "Finnish",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "fj",
    label: "Fijian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "fo",
    label: "Faroese",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "fr",
    label: "French",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "fy",
    label: "Western Frisian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ga",
    label: "Irish",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "gd",
    label: "Scottish Gaelic",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "gl",
    label: "Galician",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "gn",
    label: "Guaraní",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "gu",
    label: "Gujarati",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "gv",
    label: "Manx",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ha",
    label: "Hausa",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "he",
    label: "Hebrew",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "hi",
    label: "Hindi",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ho",
    label: "Hiri Motu",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "hr",
    label: "Croatian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ht",
    label: "Haitian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "hu",
    label: "Hungarian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "hy",
    label: "Armenian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "hz",
    label: "Herero",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ia",
    label: "Interlingua",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "id",
    label: "Indonesian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ie",
    label: "Interlingue",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ig",
    label: "Igbo",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ii",
    label: "Nuosu",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ik",
    label: "Inupiaq",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "io",
    label: "Ido",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "is",
    label: "Icelandic",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "it",
    label: "Italian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "iu",
    label: "Inuktitut",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ja",
    label: "Japanese",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "jv",
    label: "Javanese",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ka",
    label: "Georgian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "kg",
    label: "Kongo",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ki",
    label: "Kikuyu",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "kj",
    label: "Kwanyama",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "kk",
    label: "Kazakh",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "kl",
    label: "Kalaallisut",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "km",
    label: "Khmer",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "kn",
    label: "Kannada",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ko",
    label: "Korean",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "kr",
    label: "Kanuri",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ks",
    label: "Kashmiri",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ku",
    label: "Kurdish",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "kv",
    label: "Komi",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "kw",
    label: "Cornish",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ky",
    label: "Kyrgyz",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "la",
    label: "Latin",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "lb",
    label: "Luxembourgish",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "lg",
    label: "Ganda",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "li",
    label: "Limburgish",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ln",
    label: "Lingala",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "lo",
    label: "Lao",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "lt",
    label: "Lithuanian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "lu",
    label: "Luba-Katanga",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "lv",
    label: "Latvian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "mg",
    label: "Malagasy",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "mh",
    label: "Marshallese",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "mi",
    label: "Māori",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "mk",
    label: "Macedonian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ml",
    label: "Malayalam",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "mn",
    label: "Mongolian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "mr",
    label: "Marathi",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ms",
    label: "Malay",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "mt",
    label: "Maltese",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "my",
    label: "Burmese",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "na",
    label: "Nauru",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "nb",
    label: "Norwegian Bokmål",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "nd",
    label: "Northern Ndebele",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ne",
    label: "Nepali",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ng",
    label: "Ndonga",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "nl",
    label: "Dutch",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "nn",
    label: "Norwegian Nynorsk",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "no",
    label: "Norwegian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "nr",
    label: "Southern Ndebele",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "nv",
    label: "Navajo",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ny",
    label: "Chichewa",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "oc",
    label: "Occitan",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "oj",
    label: "Ojibwe",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "om",
    label: "Oromo",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "or",
    label: "Oriya",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "os",
    label: "Ossetian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "pa",
    label: "Panjabi",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "pi",
    label: "Pāli",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "pl",
    label: "Polish",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ps",
    label: "Pashto",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "pt",
    label: "Portuguese",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "qu",
    label: "Quechua",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "rm",
    label: "Romansh",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "rn",
    label: "Kirundi",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ro",
    label: "Romanian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ru",
    label: "Russian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "rw",
    label: "Kinyarwanda",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "sa",
    label: "Sanskrit",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "sc",
    label: "Sardinian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "sd",
    label: "Sindhi",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "se",
    label: "Northern Sami",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "sg",
    label: "Sango",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "si",
    label: "Sinhala",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "sk",
    label: "Slovak",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "sl",
    label: "Slovene",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "sm",
    label: "Samoan",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "sn",
    label: "Shona",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "so",
    label: "Somali",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "sq",
    label: "Albanian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "sr",
    label: "Serbian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ss",
    label: "Swati",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "st",
    label: "Southern Sotho",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "su",
    label: "Sundanese",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "sv",
    label: "Swedish",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "sw",
    label: "Swahili",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ta",
    label: "Tamil",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "te",
    label: "Telugu",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "tg",
    label: "Tajik",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "th",
    label: "Thai",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ti",
    label: "Tigrinya",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "tk",
    label: "Turkmen",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "tl",
    label: "Tagalog",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "tn",
    label: "Tswana",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "to",
    label: "Tonga",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "tr",
    label: "Turkish",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ts",
    label: "Tsonga",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "tt",
    label: "Tatar",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "tw",
    label: "Twi",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ty",
    label: "Tahitian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ug",
    label: "Uyghur",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "uk",
    label: "Ukrainian",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ur",
    label: "Urdu",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "uz",
    label: "Uzbek",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "ve",
    label: "Venda",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "vi",
    label: "Vietnamese",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "vo",
    label: "Volapük",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "wa",
    label: "Walloon",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "wo",
    label: "Wolof",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "xh",
    label: "Xhosa",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "yi",
    label: "Yiddish",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "yo",
    label: "Yoruba",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "za",
    label: "Zhuang",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "zh",
    label: "Chinese",
    system: "http://www.iso.org/iso-639-1",
  },
  {
    value: "zu",
    label: "Zulu",
    system: "http://www.iso.org/iso-639-1",
  },
];

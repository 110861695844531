// action types
import {
  UNAUTHORIZED_ACCESS,
  FETCH_ACCESS_TOKEN_SUCCESS,
} from "../actions/actions";

const initialState = {
  isAuthorized: false,
  access_token: null,
  dosespot: {
    access_token: null,
  },
  fetched: false,
  fetching: false,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case UNAUTHORIZED_ACCESS: {
      state = {
        ...state,
        isAuthorized: false,
      };
      break;
    }
    case FETCH_ACCESS_TOKEN_SUCCESS: {
      state = {
        ...state,
        isAuthorized: true,
        access_token: action.access_token,
      };
      break;
    }
    default: {
      break;
    }
  }
  return state;
};

export default authReducer;

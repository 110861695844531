import React from "react";
import { withStyles } from "@material-ui/core";
import AppointmentOptionsForm from "../forms/AppointmentOptionsForm";
import PatientForm from "../forms/PatientForm";
import CircularProgress from "@material-ui/core/CircularProgress";
import NavBar from "./NavBar";
import BootstrapContainerGrid from "./BootstrapContainerGrid";
import { connect } from "react-redux";
import { DATE_TIME_FORMAT } from "../consts";
import moment from "moment";
import AddToCalendar from "./AddToCalendar";

const styles = () => ({
  container: {
    marginTop: "5%",
    textAlign: "center",
  },
  buttonProgress: {
    position: "relative",
    left: "50%",
  },
});

const AppointmentOptions = props => {
  return (
    <NavBar>
      {props.fetching && (
        <BootstrapContainerGrid>
          <CircularProgress
            size={24}
            className={`${props.classes.buttonProgress} mx-auto`}
          />
        </BootstrapContainerGrid>
      )}
      {props.fetched &&
        props.error === null &&
        !props.selectedPatient &&
        !props.bookedAppointmentSuccess && <PatientForm />}
      {props.fetched &&
        props.error === null &&
        props.selectedPatient &&
        !props.bookedAppointmentSuccess && <AppointmentOptionsForm />}
      {props.fetched &&
        props.error === null &&
        props.selectedPatient &&
        props.bookedAppointmentSuccess &&
        props.bookedAppointment && (
          <div className="min-h-full h-auto mx-auto w-full max-w-5xl bg-white shadow-md rounded px-8 py-8 pt-8 text-center">
            <h3 className="text-lg font-bold pb-1">
              Appointment successfully booked
            </h3>
            <h4 className="text-blue-700 font-bold">
              {props.bookedAppointment.title}
            </h4>
            <p className="text-grey-600">
              Description: {props.bookedAppointment.description}
            </p>
            <p className="text-grey-600">
              Start:{" "}
              {moment(props.bookedAppointment.startTime).format(
                DATE_TIME_FORMAT
              )}
            </p>
            <p className="text-grey-600">
              End:{" "}
              {moment(props.bookedAppointment.endTime).format(DATE_TIME_FORMAT)}
            </p>
            <br />
            {/* <Button variant="outlined"> */}
            <AddToCalendar
              event={props.bookedAppointment}
              // buttonTemplate={{ "calendar-plus-o": "left" }}
            />
            {/* </Button> */}
          </div>
        )}
      {props.error && <h3>Failed to load page: {props.error}</h3>}
    </NavBar>
  );
};

const mapStateToProps = state => {
  return {
    settingsFetched: state.settings.fetched,
    settingsFetching: state.settings.fetching,
    settingsError: state.settings.error,
    selectedPatient: state.patients.selectedPatient,
    bookedAppointmentSuccess: state.schedule.bookedAppointmentSuccess,
    bookedAppointment: state.schedule.bookedAppointment,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(AppointmentOptions));

// action types
import {
  REQUEST_SETTINGS,
  FETCH_SETTINGS_SUCCEEDED,
  FETCH_SETTINGS_FAILED,
  SET_USER,
  REQUEST_USER,
  FETCH_USER_SUCCEEDED,
  FETCH_USER_FAILED,
  SET_ORGANIZATION,
  REQUEST_ORGANIZATION,
  FETCH_ORGANIZATION_SUCCEEDED,
  FETCH_ORGANIZATION_FAILED,
  SET_EVENT,
  REQUEST_EVENTS,
  FETCH_EVENTS_SUCCEEDED,
  FETCH_EVENTS_FAILED,
} from "../actions/actions";

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  user: {
    firstName: null,
    lastName: null,
    prefix: null,
    suffix: null,
    practitionerId: null,
    email: null,
    phoneNumber: null,
    roles: [],
    avatar: null,
    organizationId: null,
    availability: {
      sundayStartHour: null,
      sundayEndHour: null,
      sunday: false,
      mondayStartHour: null,
      mondayEndHour: null,
      monday: false,
      tuesdayStartHour: null,
      tuesdayEndHour: null,
      tuesday: false,
      wednesdayStartHour: null,
      wednesdayEndHour: null,
      wednesday: false,
      thursdayStartHour: null,
      thursdayEndHour: null,
      thursday: false,
      fridayStartHour: null,
      fridayEndHour: null,
      friday: false,
      saturdayStartHour: null,
      saturdayEndHour: null,
      saturday: false,
      timezone: null,
    },
    eventIds: [],
  },
  organization: {
    name: null,
    publicLink: null,
    team: [],
    eventIds: [],
  },
  events: [],
  eventsUpdated: false,
  templates: [],
  templatesUpdated: false,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SETTINGS: {
      state = {
        ...state,
        fetched: false,
        error: null,
      };
      break;
    }
    case SET_USER: {
      state = {
        ...state,
        user: action.payload,
      };
      break;
    }
    case REQUEST_USER: {
      state = {
        ...state,
        fetched: false,
        fetching: true,
        error: null,
      };
      break;
    }
    case FETCH_USER_SUCCEEDED: {
      state = {
        ...state,
        user: action.user,
        fetched: true,
        fetching: false,
        error: null,
      };
      break;
    }
    case FETCH_USER_FAILED: {
      state = {
        ...state,
        fetched: true,
        fetching: false,
        error: action.error,
      };
      break;
    }
    case SET_ORGANIZATION: {
      let organization = { ...state.organization };
      organization.name = action.payload.name;
      organization.publicLink = action.payload.publicLink;
      organization.timezone = action.payload.timezone;
      state = {
        ...state,
        organization: organization,
      };
      break;
    }
    case REQUEST_ORGANIZATION: {
      state = {
        ...state,
        fetched: false,
        fetching: true,
        error: null,
      };
      break;
    }
    case FETCH_ORGANIZATION_SUCCEEDED: {
      state = {
        ...state,
        organization: action.organization,
        fetched: true,
        fetching: false,
        error: null,
      };
      break;
    }
    case FETCH_ORGANIZATION_FAILED: {
      state = {
        ...state,
        fetched: true,
        fetching: false,
        error: action.error,
      };
      break;
    }
    case SET_EVENT: {
      let events = state.events;
      if (action.payload) {
        events.push(action.payload);
      }
      state = {
        ...state,
        events: events,
        eventsUpdated: !state.eventsUpdated,
      };
      break;
    }
    case REQUEST_EVENTS: {
      state = {
        ...state,
        fetched: false,
        fetching: true,
        error: null,
      };
      break;
    }
    case FETCH_EVENTS_SUCCEEDED: {
      state = {
        ...state,
        events: action.events,
        fetched: true,
        fetching: false,
        error: null,
      };
      break;
    }
    case FETCH_EVENTS_FAILED: {
      state = {
        ...state,
        fetched: true,
        fetching: false,
        error: action.error,
      };
      break;
    }
    case FETCH_SETTINGS_SUCCEEDED: {
      state = {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        allowPatientBooking: action.allowPatientBooking,
      };
      break;
    }
    case FETCH_SETTINGS_FAILED: {
      state = {
        ...state,
        fetching: false,
        fetched: false,
        error: action.error,
      };
      break;
    }
    default: {
      break;
    }
  }
  return state;
};

export default settingsReducer;

import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import BootstrapContainerGrid from "./BootstrapContainerGrid";

const styles = theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#ffffff",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing.unit,
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing.unit * 3,
    },
  },
  brandIcon: {
    maxWidth: "230px",
    maxHeight: "75px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
});

class NavBar extends React.Component {
  render() {
    const {
      classes,
      children,
      organization,
      fetched,
      error,
      fetching,
    } = this.props;

    return (
      <div className={classes.root}>
        {fetching && (
          <BootstrapContainerGrid>
            <CircularProgress
              size={24}
              className={`${this.props.classes.buttonProgress} mx-auto`}
            />
          </BootstrapContainerGrid>
        )}
        {fetched && error === null && (
          <React.Fragment>
            <CssBaseline />
            <AppBar
              position="fixed"
              className={`${classes.appBar} border-b border-gray-400`}
              elevation={0}
            >
              <Toolbar>
                <img
                  src={
                    organization.logo
                      ? organization.logo
                      : "https://akute-website-static-assets.s3.amazonaws.com/AkuteHealth_FinalArtwork_1024.png"
                  }
                  alt={organization.name}
                  className={classes.brandIcon}
                />
              </Toolbar>
            </AppBar>
            <main className={classes.content}>
              <div className={classes.toolbar} />
              {children}
            </main>
          </React.Fragment>
        )}
      </div>
    );
  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    organization: state.settings.organization,
    fetching: state.settings.fetching,
    fetched: state.settings.fetched,
    error: state.settings.error,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles, { withTheme: true })(withRouter(NavBar)));

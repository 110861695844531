import { applyMiddleware, createStore } from "redux";
import rootReducer from "./reducers/reducer";
// import promise from "redux-promise-middleware";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas/sagas";

let middlewares = [];
const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);
if (process.env.REACT_APP_ENVIRONMENT !== "production") {
  const { logger } = require("redux-logger");
  middlewares.push(logger);
}
const middleware = applyMiddleware(...middlewares);
const persistedState = loadFromLocalStorage();
let store;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  store = createStore(rootReducer, persistedState, middleware);
} else {
  store = createStore(rootReducer, middleware);
}

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch (error) {
    // console.error(error);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = sessionStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    // console.error(error);
    return undefined;
  }
}

sagaMiddleware.run(rootSaga);

store.subscribe(() => {
  if (process.env.REACT_APP_ENVIRONMENT === "development") {
    // saveToLocalStorage(store.getState());
  } else {
    // saveToLocalStorage({ auth: store.getState().auth });
  }
});

export default store;

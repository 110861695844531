import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
// import green from "@material-ui/core/colors/green";
import DeleteButton from "../../containers/DeleteButton";

const styles = theme => ({
  buttonProgress: {
    color: theme.palette.primary,
    position: "absolute",
    marginTop: 8,
    marginLeft: 30,
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: "relative",
  },
});

let renderSubmitButton = props => {
  const {
    form: { isSubmitting, submitFailed },
    handleDelete,
    allowDelete,
    classes,
    deleteTitle,
    deleteText,
    deleteForm,
    noConfirmation,
    buttonWidth,
    disabled,
  } = props;
  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        {isSubmitting && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitting || disabled}
          className="btn btn-blue"
          style={{ marginRight: "5px", width: buttonWidth }}
        >
          {props.children}
        </Button>
        {allowDelete && (
          <DeleteButton
            variant="contained"
            color="secondary"
            type="button"
            disabled={isSubmitting}
            onDelete={handleDelete}
            title={deleteTitle}
            deleteForm={deleteForm}
            noConfirmation={noConfirmation}
          >
            {deleteText ? deleteText : "Delete"}
          </DeleteButton>
        )}
      </div>
      <FormControl error={submitFailed !== undefined}>
        {submitFailed && <FormHelperText>Error</FormHelperText>}
      </FormControl>
    </React.Fragment>
  );
};

// const mapDispatchToProps = dispatch => ({

// });

// export default connect(
//   null,
//   mapDispatchToProps,
// )(withStyles(styles)(renderSubmitButton));

export default withStyles(styles)(renderSubmitButton);

// export default formValues(props => ({
//   submitted: props.submitting,
//   reset: props.reset,
//   submitFailed: props.submitFailed,
// }))(withStyles(styles)(SubmitFormButton));

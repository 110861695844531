import { combineReducers } from "redux";
import settingsReducer from "./settingsReducer";
import scheduleReducer from "./scheduleReducer";
import patientReducer from "./patientReducer";
import authReducer from "./authReducer";
import questionnaireReducer from "./questionnaireReducer";
import { RESET_STORE } from "../actions/actions";

const appReducer = combineReducers({
  schedule: scheduleReducer,
  patients: patientReducer,
  settings: settingsReducer,
  auth: authReducer,
  questionnaires: questionnaireReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  withStyles,
} from "@material-ui/core";

const styles = theme => ({
  wrapper: {
    margin: theme.spacing.unit,
    position: "center",
    marginBottom: "1em",
    justifyContent: "center",
  },
});

const ConfirmationDialog = props => (
  <Dialog open={props.open} onClose={props.onCancel}>
    <DialogTitle>{props.title}</DialogTitle>
    <DialogActions className={props.classes.wrapper}>
      {props.deleteForm && props.deleteForm}
      <Button
        variant="contained"
        color={"primary"}
        type="button"
        style={{
          marginRight: "5px",
        }}
        onClick={props.onConfirm}
      >
        Confirm
      </Button>
      <Button
        variant="contained"
        color={"secondary"}
        type="button"
        style={{
          marginRight: "0",
        }}
        onClick={props.onCancel}
      >
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
);

export default withStyles(styles)(ConfirmationDialog);

import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import BootstrapContainerGrid from "../components/BootstrapContainerGrid";
import NavBar from "../components/NavBar";
import QuestionnaireForm from "../forms/QuestionnaireForm";
import PatientForm from "../forms/PatientForm";
import { getFormBundle } from "../utils/asyncSettingsServerRequests";
import { FETCH_ACCESS_TOKEN_SUCCESS } from "../actions/actions";
import { getAccessToken } from "../utils/asyncSettingsServerRequests";
import {
  getFhirQuestionnaire,
  submitQuestionnaireResponses,
} from "../utils/asyncServerRequests";

const styles = () => ({
  buttonProgress: {
    position: "relative",
  },
});

class QuestionnaireContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      fetched: false,
      error: null,
      questionnaire: null,
      initialValues: {},
      questionnaireAnswers: null,
      questionnaireIndex: 0,
      completed: false,
      formBundle: null,
    };

    this.getQuestionnaires = this.getQuestionnaires.bind(this);
    this.handlePageLoad = this.handlePageLoad.bind(this);
    this.handleQuestionnaireSubmit = this.handleQuestionnaireSubmit.bind(this);
  }

  componentDidMount() {
    this.handlePageLoad();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedPatient !== this.props.selectedPatient) {
      this.handlePageLoad();
    }
  }

  handlePageLoad = async () => {
    this.setState({
      fetching: true,
      fetched: false,
    });
    if (!this.props.isAuthorized || !this.props.access_token) {
      let jwtResponse = await getAccessToken();
      if (jwtResponse && jwtResponse.code < 400) {
        this.setState({
          fetching: false,
          fetched: true,
        });
        this.props.setAccessToken(jwtResponse.access_token);
      } else {
        let message = jwtResponse ? jwtResponse.message : "Error";
        console.error(message);
        message = "Network error occurred. Please try refreshing.";
        this.setState({
          fetching: false,
          fetched: false,
          error: message,
        });
      }
    }
    let formResponse = await getFormBundle(this.props.match.params.formId);
    if (formResponse && formResponse.code < 400) {
      const formBundle = formResponse.data;
      if (!(formBundle && formBundle.active === true)) {
        const message =
          "This form is no longer available. Please reach out to us for more information.";
        this.setState({
          error: message,
          fetching: false,
          fetched: false,
        });
      } else {
        this.setState({
          formBundle: formResponse.data,
          fetching: false,
          fetched: true,
        });
      }

      await this.getQuestionnaires(formResponse.data);
    } else {
      let message = formResponse
        ? formResponse.message
        : "Network error occurred. Please try refreshing.";
      console.error(message);
      this.setState({
        error: message,
        fetching: false,
        fetched: false,
      });
    }
  };

  getQuestionnaires = async (
    formBundle = this.state.formBundle,
    index = this.state.questionnaireIndex
  ) => {
    if (
      formBundle &&
      Array.isArray(formBundle.questionnaires) &&
      formBundle.questionnaires.length > index
    ) {
      let patientId =
        this.props.selectedPatient && this.props.selectedPatient.id;
      if (!patientId) {
        return;
      }
      let questionnaireResponse = await getFhirQuestionnaire(
        formBundle.questionnaires[index],
        patientId
      );
      if (questionnaireResponse && questionnaireResponse.code < 400) {
        let questionnaire = questionnaireResponse.questionnaire;
        let questionnaireAnswers = questionnaireResponse.answers;
        this.setState({
          questionnaire: questionnaire,
          questionnaireAnswers: questionnaireAnswers,
          questionnaireIndex: index,
        });
      } else {
        let message = questionnaireResponse
          ? questionnaireResponse.message
          : "Error";
        console.error(message);
        if (questionnaireResponse.errorCode === "not-found") {
          message =
            "No forms found. Check with your clinician to get the correct link.";
        } else {
          message = "Network error occurred. Please try refreshing.";
        }
        this.setState({
          error: message,
        });
      }
      return true;
    } else if (
      !formBundle ||
      !Array.isArray(formBundle.questionnaires) ||
      formBundle.questionnaires.length === 0
    ) {
      this.setState({
        error:
          "No forms found. Check with your clinician to get the correct link.",
      });
    }
  };

  handleQuestionnaireSubmit = async () => {
    let nextQuestionnaireIndex = this.state.questionnaireIndex + 1;
    let questionnaireExists = await this.getQuestionnaires(
      this.state.formBundle,
      nextQuestionnaireIndex
    );
    if (!questionnaireExists) {
      if (
        Array.isArray(this.props.pendingQuestionnaireResponses) &&
        this.props.pendingQuestionnaireResponses.length
      ) {
        const response = await submitQuestionnaireResponses(
          this.props.pendingQuestionnaireResponses,
          this.state.formBundle,
          this.props.selectedPatient,
          this.props.newPatient
        );
        if (!(response && response.statusCode < 400)) {
          const message =
            response && response.error
              ? response.error
              : "Error submitting form";
          this.props.enqueueSnackbar(message, {
            variant: "error",
          });
        }
      }
      this.setState({
        completed: true,
      });
    }
    if ("scrollRestoration" in this.props.history) {
      this.props.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
  };

  render() {
    const { classes, selectedPatient } = this.props;
    return (
      <NavBar>
        {this.state.fetching && (
          <BootstrapContainerGrid>
            <CircularProgress
              size={24}
              className={`${classes.buttonProgress} mx-auto`}
            />
          </BootstrapContainerGrid>
        )}
        {this.state.fetched &&
          this.state.error === null &&
          !this.state.completed && (
            <React.Fragment>
              {this.state.questionnaire && selectedPatient && (
                <React.Fragment>
                  <h1 className="text-xl font-bold max-w-5xl pl-6 pb-2 mx-auto">
                    {this.state.questionnaire.title}
                  </h1>
                  <QuestionnaireForm
                    questionnaire={this.state.questionnaire}
                    savedQuestionnaireAnswers={this.state.questionnaireAnswers}
                    initialValues={this.state.initialValues}
                    handleQuestionnaireSubmit={this.handleQuestionnaireSubmit}
                    formBundle={this.state.formBundle}
                  />
                </React.Fragment>
              )}
              {!selectedPatient && (
                <PatientForm formBundle={this.state.formBundle} />
              )}
            </React.Fragment>
          )}
        {this.state.completed && (
          <React.Fragment>
            <h1 className="text-2xl font-bold max-w-5xl mx-auto pt-6">
              Your answers have been submitted! Your provider will review your
              answers and be in touch if necessary.
            </h1>
          </React.Fragment>
        )}
        {this.state.error && <h3>{this.state.error}</h3>}
      </NavBar>
    );
  }
}

const mapStateToProps = state => {
  return {
    access_token: state.auth.access_token,
    isAuthorized: state.auth.isAuthorized,
    selectedPatient: state.patients.selectedPatient,
    newPatient: state.patients.newPatient,
    patientCreated: state.patients.patientCreated,
    pendingQuestionnaireResponses:
      state.questionnaires.pendingQuestionnaireResponses,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAccessToken: access_token =>
      dispatch({
        type: FETCH_ACCESS_TOKEN_SUCCESS,
        access_token: access_token,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(QuestionnaireContainer));

import { parseHumanName } from "./resources";
import moment from "moment";

export const getPatientId = patientsResource => {
  let patientId = [];
  for (let i = 0; i < patientsResource.length; i++) {
    let name = parseHumanName(patientsResource[i].name);
    patientId.push({
      id: patientsResource[i].id,
      name: name,
      dob: getPatientBirthdate(patientsResource[i]),
    });
  }
  return patientId;
};

export const getPatientIdentifier = (patientResource, system) => {
  if (!patientResource.identifier) {
    return null;
  }
  let value = null;
  patientResource.identifier.forEach(identifier => {
    if (identifier.system === system) {
      value = identifier.value;
      return;
    }
  });
  return value;
};

export const getPatientOfficialHumanName = patientResource => {
  if (Array.isArray(patientResource.name) && patientResource.name.length >= 1) {
    for (let i = 0; i < patientResource.name.length; i++) {
      if (patientResource.name[i].use === "official") {
        return patientResource.name[i];
      }
    }
    return patientResource[0];
  } else {
    return null;
  }
};

export const addPatientIdentifier = (
  patientResource,
  system,
  value,
  assigner
) => {
  if (!patientResource.identifier) {
    patientResource.identifier = [];
  }
  patientResource.identifier.push({
    system: system,
    value: value,
    assigner: {
      display: assigner,
    },
  });
  return patientResource;
};

export const getPatientOptions = patients => {
  let patientOptions = [];
  for (let i = 0; i < patients.length; i++) {
    let name = parseHumanName(patients[i].name);
    patientOptions.push({
      value: patients[i].id,
      label: `${name} - DoB: ${getPatientBirthdate(patients[i])}`,
      name: name,
      patientResource: patients[i],
    });
  }
  return patientOptions;
};

export const getPatientBirthdate = patientResource => {
  return moment(patientResource.birthDate, "YYYY-MM-DD").format("MM/DD/YYYY");
};

import isMobilePhone from "validator/lib/isMobilePhone";

export const phoneValidator = value => isMobilePhone(value);

export const phoneFieldValidator = value => {
  if (!value) return undefined;
  return isMobilePhone(value) ? undefined : "Invalid phone number";
};

export const required = value => (value ? undefined : "Required");

export const emailValidator = value => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;
};

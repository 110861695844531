import React, { Component } from "react";
import { FixedSizeList as List } from "react-window";

const height = 35;

class VirtualizedList extends Component {
  render() {
    const { /*options,*/ children } = this.props;
    // const initialOffset =
    //   options.indexOf(this.props.selectProps.value) * height;
    return (
      <List
        height={
          children.length > 10
            ? height * 10
            : children.length
            ? children.length * height
            : height
        }
        itemCount={children.length ? children.length : 0}
        itemSize={height}
        // initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

export default VirtualizedList;
